import { UserRole } from '@/models/UserRole';
import BaseView from '@/modules/base/Base.vue';

export default [
    {
        path: '/verhuur',
        component: BaseView,
        name: 'rental-root',
        meta: { title: 'UVA - Verhuur ', page: 'Verhuur', roles: [UserRole.RentalUser] },
        redirect: { name: 'rentals' },
        children: [
            {
                name: 'rentals',
                path: '',
                component: () => import(/* webpackChunkName: "rental" */ '@/modules/rental/views/rental/Overview.vue'),
                meta: { title: 'UVA - Verhuur ', page: 'Overzicht', roles: [UserRole.RentalUser] },
            },
            {
                path: 'aanmaken',
                name: 'create-rental',
                component: () => import(/* webpackChunkName: "rental" */ './views/CreateOrder.vue'),
                meta: { title: 'UVA - Verhuur', scope: 'Verhuur', page: 'Aanmaken', roles: [UserRole.RentalUser], showBackButton: true },
            },
            {
                path: ':orderId',
                name: 'rental',
                component: () => import(/* webpackChunkName: "rental" */ '@/modules/rental/views/rental/Edit.vue'),
                meta: { title: 'UVA - Verhuur', page: 'Order', roles: [UserRole.RentalUser], showBackButton: true },
            },
            {
                path: ':orderId/print',
                name: 'rental-print',
                component: () => import(/* webpackChunkName: "rental" */ '@/modules/rental/views/rental/Print.vue'),
                meta: { title: 'UVA - Verhuur', scope: 'Verhuur', page: 'Print', roles: [UserRole.RentalUser], showBackButton: true },
            },
        ],
    },
    {
        path: '/werkorder',
        component: BaseView,
        name: 'work-order-root',
        meta: { title: 'UVA - Werkorder ', page: 'Werkorder', roles: [UserRole.RentalUser] },
        redirect: { name: 'work-orders' },
        children: [
            {
                path: '',
                name: 'work-orders',
                component: () => import(/* webpackChunkName: "workorder" */ '@/modules/rental/views/work-order/Overview.vue'),
                meta: { title: 'UVA - Verhuur', scope: 'Werkorder', page: 'Overzicht', roles: [UserRole.RentalUser] },
            },
            {
                path: 'aanmaken',
                name: 'create-work-order',
                component: () => import(/* webpackChunkName: "workorder" */ '@/modules/rental/views/work-order/Create.vue'),
                meta: { title: 'UVA - Verhuur', scope: 'Werkorder', page: 'Aanmaken', roles: [UserRole.RentalUser] },
            },
            {
                path: ':workOrderId',
                name: 'work-order',
                component: () => import(/* webpackChunkName: "workorder" */ '@/modules/rental/views/work-order/Edit.vue'),
                meta: { title: 'UVA - Verhuur', scope: 'Werkorder', page: 'Wijzigen', roles: [UserRole.RentalUser] },
            },
            {
                path: 'converteren/:workOrderId',
                name: 'convert-work-order',
                component: () => import(/* webpackChunkName: "workorder" */ '@/modules/rental/views/work-order/Convert.vue'),
                meta: { title: 'UVA - Verhuur', scope: 'Werkorder', page: 'Converteren', roles: [UserRole.RentalUser] },
            },
            {
                path: 'print/:workOrderId',
                name: 'print-work-order',
                alias: 'work-order-print',
                component: () => import(/* webpackChunkName: "workorder" */ '@/modules/rental/views/work-order/Print.vue'),
                meta: { title: 'UVA - Verhuur', scope: 'Werkorder', page: 'Print', roles: [UserRole.RentalUser] },
            },
        ],
    },
    {
        path: '/retouren',
        component: BaseView,
        name: 'return-root',
        meta: { title: 'UVA - Retour ', page: 'Retour', roles: [UserRole.RentalUser] },
        redirect: { name: 'returns' },
        children: [
            {
                path: '',
                name: 'returns',
                component: () => import(/* webpackChunkName: "return.overview" */ '@/modules/rental/views/return/Overview.vue'),
                meta: { title: 'UVA - Retouren ', page: 'Retouren', roles: [UserRole.RentalUser] },
            },
            {
                path: 'aanmaken',
                name: 'create-return',
                component: () => import(/* webpackChunkName: "return.create" */ '@/modules/rental/views/return/Create.vue'),
                meta: { title: 'UVA - Retouren', page: 'Aanmaken', roles: [UserRole.RentalUser], showBackButton: true },
            },
            {
                path: ':returnId',
                name: 'return',
                component: () => import(/* webpackChunkName: "return.edit" */ '@/modules/rental/views/return/Edit.vue'),
                meta: { title: 'UVA - Retouren', page: 'Wijzigen', roles: [UserRole.RentalUser], showBackButton: true },
            },
            {
                path: ':returnId/print',
                name: 'return-print',
                component: () => import(/* webpackChunkName: "return.edit" */ '@/modules/rental/views/return/Print.vue'),
                meta: { title: 'UVA - Retouren', page: 'Printen', roles: [UserRole.RentalUser], showBackButton: true },
            },
        ],
    },
    {
        path: '/afkoopvoorstel',
        name: 'buyout-root',
        component: BaseView,
        meta: { title: 'UVA - Afkoopvoorstel ', page: 'Afkoopvoorstel', roles: [UserRole.RentalUser] },
        redirect: { name: 'buyouts' },
        children: [
            {
                path: '',
                name: 'buyouts',
                component: () => import(/* webpackChunkName: "buyout.overview" */ '@/modules/rental/views/buyout/Overview.vue'),
                meta: { title: 'UVA - Afkoopvoorstel ', page: 'Afkoopvoorstel', roles: [UserRole.RentalUser] },
            },
            {
                path: 'aanmaken',
                name: 'create-buyout',
                component: () => import(/* webpackChunkName: "buyout.edit" */ '@/modules/rental/views/buyout/Create.vue'),
                meta: { title: 'UVA - Afkoopvoorstel ', page: 'Afkoopvoorstel', roles: [UserRole.RentalUser] },
            },
            {
                path: ':buyoutId/bewerk',
                name: 'buyout',
                component: () => import(/* webpackChunkName: "buyout.edit" */ '@/modules/rental/views/buyout/Edit.vue'),
                meta: {
                    title: 'UVA - Afkoopvoorstel',
                    scope: 'Afkoopvoorstel',
                    page: 'Detail',
                    roles: [UserRole.RentalUser], showBackButton: true,
                },
            },
            {
                path: ':buyoutId/print',
                name: 'buyout-print',
                component: () => import(/* webpackChunkName: "buyout.edit" */ '@/modules/rental/views/buyout/Print.vue'),
                meta: {
                    title: 'UVA - Afkoopvoorstel',
                    scope: 'Afkoopvoorstel',
                    page: 'Detail',
                    roles: [UserRole.RentalUser],
                },
            },
        ],
    },
    {
        path: 'pakbon',
        component: BaseView,
        name: 'packingslip-root',
        meta: { title: 'UVA - Pakbon ', page: 'Pakbon', roles: [UserRole.RentalUser] },
        redirect: { name: 'packinglsip' },
        children: [
            {
                name: 'packinglsip',
                path: '',
                component: () => import(/* webpackChunkName: "rental.overview" */ './views/ManualPackingSlip.vue'),
                meta: { title: 'UVA - Pakbon ', page: 'Pakbon', roles: [UserRole.RentalUser] },
            },
        ],
    },
];
