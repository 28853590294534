import Vue from 'vue';
import SiteEdit from './Site/Edit.vue';
import GridRouterLinkComponent from './Grid/GridLink.vue';
import ClientEditComponent from './Client/Edit.vue';
import EditCardComponent from './EditCard.vue';
import RentalCreateComponent from './Rental/Create.vue';
import RentalViewComponent from './Rental/View.vue';
import ReturnCreateComponent from './Return/Create.vue';
import ReturnAmountComponent from './Return/DamagedMissing.vue';
import ProductBundleEdit from '@/modules/inventory/components/ProductBundle/Edit.vue';
import GridFilterContains from './Grid/GridFilterContains.vue';
import ReturnCompleteComponent from './Return/Complete.vue';
import ReturnDamagedMissingComponent from './Return/DamagedMissing.vue';
import PrintHeaderComponent from './Print/Header.vue';
import PrintWorkDescriptionComponent from './Print/WorkDescription.vue';
import GridButtonComponent from './Grid/GridButton.vue';
import GridPriorityComponent from './Grid/GridPriority.vue';
import YearMontSelector from './YearMonthSelector.vue';
import GridProductsColumn from './Grid/GridProductsColumn.vue';
import GridBadge from './Grid/GridBadge.vue';
import GridLinkPriorityCombined from './Grid/GridLinkPriorityCombined.vue';
import RentalProductsAndbundles from './Rental/ProductsAndBundles.vue';
import RentalOrderedDeliverd from './Rental/OrderedDelivered.vue';
import ReturnServicesComponent from './Return/Services.vue';
import NavigationBar from './Navigation/Bar.vue';
import ReturnProductWithSerial from './Return/ProductsWithSerial.vue';
import GridCheckbox from './Grid/GridCheckbox.vue';
import BuyoutView from './Buyout/View.vue';
import GridHtmlComponent from './Grid/GridHtml.vue';
import OverflowContainer from './OverflowContainer.vue';
import standard from './standard';
import RepositoryGridVue from './RepositoryGrid.vue';
// Third party
import GridTooltipVue from './Grid/GridTooltip.vue';
import UniversalGridWrapper from './Grid/UniversalGridWrapper.vue';

Vue.component('grid-link', GridRouterLinkComponent);
Vue.component('grid-button', GridButtonComponent);
Vue.component('grid-priority', GridPriorityComponent);
Vue.component('grid-filter-contains', GridFilterContains);
Vue.component('grid-products-column', GridProductsColumn);
Vue.component('grid-badge', GridBadge);
Vue.component('grid-link-priority-combined', GridLinkPriorityCombined);
Vue.component('grid-checkbox', GridCheckbox);
Vue.component('grid-html', GridHtmlComponent);
Vue.component('grid-tooltip', GridTooltipVue);
Vue.component('prd-repo-grid', RepositoryGridVue);
// this is an copy of prd-grid-wrapper, only this one has also back-end paging implemented.
Vue.component('universal-grid-wrapper', UniversalGridWrapper);

Vue.component('rental-create', RentalCreateComponent);
Vue.component('rental-view', RentalViewComponent);
Vue.component('rental-products-and-bundles', RentalProductsAndbundles);
Vue.component('rental-ordered-delivered', RentalOrderedDeliverd);

Vue.component('return-create', ReturnCreateComponent);
Vue.component('return-amount', ReturnAmountComponent);
Vue.component('return-complete', ReturnCompleteComponent);
Vue.component('return-damaged-missing', ReturnDamagedMissingComponent);
Vue.component('return-products-with-serial', ReturnProductWithSerial);
Vue.component('return-services', ReturnServicesComponent);

Vue.component('buyout-view', BuyoutView);

Vue.component('bundle-edit', ProductBundleEdit);
Vue.component('client-edit', ClientEditComponent);
Vue.component('site-edit', SiteEdit);

Vue.component('print-header', PrintHeaderComponent);
Vue.component('print-work-description', PrintWorkDescriptionComponent);

Vue.component('year-month-selector', YearMontSelector);
Vue.component('navigation-bar', NavigationBar);
Vue.component('EditCard', EditCardComponent);
Vue.component('overflow-container', OverflowContainer);

Object.keys(standard).forEach((key: string) => {
    Vue.component(key, standard[key]);
});
