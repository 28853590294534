








import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class PrdTableLoader extends Vue {
    @Prop({ type: Number, default: 2 }) public rows: number;
    @Prop({ type: Number, default: 2 }) public columns: number;
    @Prop({ type: Boolean, default: false }) public showHeader: boolean;
    @Prop({ type: Boolean, default: true }) public small: boolean;
    @Prop({ type: Boolean, default: true }) public borderless: boolean;

    public get props() {
        return {
            small: this.small,
            borderless: this.borderless,
            fixed: true,
        };
    }
}
