import { UserRole } from '@/models/UserRole';
import BaseView from '@/modules/base/Base.vue';

export default {
    path: '/rapportages',
    name: 'report-management',
    component: BaseView,
    meta: { page: 'Rapportages', roles: [UserRole.UniversalAdmin] },
    redirect: { name: 'revenue-report' },
    children: [
        {
            path: 'omzet',
            name: 'revenue-report',
            component: () => import(/* webpackChunkName: "settings" */ './views/RevenueReport.vue'),
            meta: {
                title: 'UVA - Rapportages',
                scope: 'Financieel',
                page: 'Omzet',
                roles: [UserRole.UniversalAdmin],
            },
        },
        {
            path: 'manco-schade',
            name: 'damage-report',
            component: () => import(/* webpackChunkName: "settings" */ './views/DamageReport.vue'),
            meta: {
                title: 'UVA - Rapportages',
                scope: 'Manco / schade',
                page: 'Omzet',
                roles: [UserRole.UniversalAdmin],
            },
        },
        {
            path: 'mutaties',
            name: 'inventory-mutations',
            component: () => import(/* webpackChunkName: "settings" */ './views/InventoryMutations.vue'),
            meta: {
                title: 'UVA - Rapportages',
                scope: 'Mutaties',
                page: 'Voorraad',
                roles: [UserRole.UniversalAdmin],
            },
        },
    ],
};
