import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class EditCardComponent extends Vue {
    @Prop({ default: '' }) public title: string;
    @Prop({ default: false }) public editMode: boolean;

    public toggled() {
        this.$emit('toggled');
        this.$emit('update:editMode', !this.editMode);
    }
}
