import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Return from '@/models/Return/Return';
import ConstructionSite from '@/models/ConstructionSite/ConstructionSite';
import Client from '@/modules/client/models/Client';
import ReturnLine from '@/models/Return/ReturnLine';
import Order from '@/models/Order/Order';
import OrderBundle from '@/models/Order/OrderBundle';
import BaseComponentWithValidations from '../BaseComponentWithValidations';
import ReturnBundle from '@/models/Return/ReturnBundle';
import ReturnBundleProduct from '@/models/Return/ReturnBundleProduct';
import { BModal } from 'bootstrap-vue';
import OrderBundleProduct from '@/models/Order/OrderBundleProduct';
import ReturnBundleVm from '@/models/Return/ReturnBundleVm';
import ReturnBundleProductVm from '@/models/Return/ReturnBundleProductVm';
import SquareMeterHelper from '@/helpers/SquareMeterHelper';
import ReturnHelper from '@/helpers/ReturnHelper';
import ClientContact from '@/modules/client/models/ClientContact';
import Product from '@/modules/inventory/models/Product';
import { ReturnStatus } from '@/models/Return/ReturnStatus';
import ProductBundle from '@/modules/inventory/models/ProductBundle';
import ProductWithSerial from '@/modules/inventory/models/ProductWithSerial';

@Component
export default class ReturnCreateComponent extends BaseComponentWithValidations {
    @Prop() public banaan: Return;

    public isLoading: boolean = true;
    public clients: Client[] = [];
    public sites: ConstructionSite[] = [];
    public isClientsLoading: boolean = true;
    public isSitesLoading: boolean = true;
    public isLoadingProductsAndOrders: boolean = true;
    public ordersOfSite: Order[] = [];
    public returnsOfSite: Return[] = [];
    public numberToAdd: number = null;
    public selectedReturnLine: ReturnLine = new ReturnLine();
    public selectedReturnBundleProduct: ReturnBundleProduct = new ReturnBundleProduct();
    public bundleAmounts: any = {};
    public update: number = 0;
    public $refs!: {
        returnLineModal: BModal,
        returnBundleProductModal: BModal,
    };
    public clientNotFound: boolean = false;
    public siteNotFound: boolean = false;
    protected returnHelper: ReturnHelper = null;
    private squareMeterHelper = null;

    public async mounted() {
        if (this.$route.query) {
            this.initQueryParams(this.$route.query);
        }

        if (this.banaan.constructionSiteId) {
            await this.loadProductsAndOrders();
        }

        this.$watch('banaan.constructionSiteId', async () => {
            await this.loadProductsAndOrders();
        });

        this.banaan.returnBundles.sortBy('sortOrder', false);
        this.banaan.returnLines.sortBy('sortOrder', false);
        this.isLoading = false;
    }

    public renderContactName(contact: ClientContact) {
        return `${contact.firstName} ${contact.lastName}`;
    }

    public removeLine(vReturnLine: ReturnLine) {
        const returnLine = this.banaan.returnLines.find((r: ReturnLine) => {
            if (vReturnLine.returnLineId) {
                return r.returnLineId === vReturnLine.returnLineId;
            }
            return r.productId === vReturnLine.productId;
        });
        const index = this.banaan.returnLines.indexOf(returnLine);
        this.banaan.returnLines.splice(index, 1);
    }

    public removeReturnBundle(productBundleId: number) {
        const returnBundles = this.banaan.returnBundles.slice(0);
        this.banaan.returnBundles = [];

        for (let i = 0; i < returnBundles.length; i++) {
            if (returnBundles[i].productBundleId !== productBundleId) {
                this.banaan.returnBundles.push(returnBundles[i]);
            }
        }
    }

    public returnContainsClientAndSite(): boolean {
        return this.banaan.client != null && this.banaan.constructionSite != null;
    }

    public returnLineOptions(onlyProducts: boolean = false) {
        const options = [];
        if (!this.banaan.isBooked() && !this.banaan.isConcept()) {
            this.returnHelper.remainingProducts.forEach((product: Product) => {
                options.push({
                    name: `${product.name}`,
                    product,
                    type: 'product',
                });
            });
        } else {
            this.returnHelper.remainingProductsWithSerial.forEach((product: ProductWithSerial) => {
                const serialLabel = product.serialNumber ? `(${product.serialNumber})` : '';
                options.push({
                    name: `${product.name} ${serialLabel}`,
                    product,
                    type: 'product',
                });
            });
        }

        this.returnHelper.remainingBundles.forEach((orderBundle: ProductBundle) => {
            if (!this.banaan.returnBundles.find((returnBundle: ReturnBundle) => {
                return returnBundle.productBundleId === orderBundle.productBundleId;
            })) {
                if (onlyProducts) {
                    orderBundle.products.forEach((product: Product) => {
                        options.push({
                            name: product.name,
                            product,
                            type: 'product',
                        });
                    });
                } else {
                    options.push({
                        name: `${orderBundle.name} (bundel)`,
                        orderBundle,
                        type: 'bundle',
                    });
                }
            }
        });

        return options;
    }

    public returnLineOptionSelected(obj: any) {
        const toAdd = this.numberToAdd ? parseInt(this.numberToAdd.toString()) : 0;
        switch (obj.type) {
            case 'product':
                const productToAdd = toAdd ? toAdd : this.getRemainingProducts(obj.product, obj.product.serialNumber);
                this.addReturnLine(obj.product as ProductWithSerial, productToAdd);
                break;
            case 'bundle':
                const bundle: ProductBundle = obj.orderBundle;
                this.addReturnBundle(bundle, toAdd);
                break;
        }

        this.numberToAdd = null;
    }

    public showReturnLine(returnLine: ReturnLine) {
        this.selectedReturnLine = returnLine;
        this.$refs.returnLineModal.show();
    }

    public getRemainingProducts(product: Product, serialNumber: string): number {
        if ((this.banaan.isConcept() || this.banaan.isBooked()) && product.serialNumbers.length > 0) {
            return this.returnHelper.getRemainingProductWithSerial(product.productId, serialNumber);
        }
        return this.returnHelper.getRemainingProduct(product.productId);
    }

    public showReturnBundleProductDetail(product: ReturnBundleProduct) {
        this.selectedReturnBundleProduct = product;
        this.$refs.returnBundleProductModal.show();
    }

    public getBundlesIdsOfProduct(productId: number) {
        const bundles = [];
        this.banaan.returnBundles.forEach((bundle: ReturnBundle) => {
            const product = bundle.products.find((p: ReturnBundleProduct) => {
                return p.productId === productId;
            });
            if (product) {
                bundles.push({
                    productBundleId: bundle.productBundleId,
                    productAmount: product.productAmount,
                });
            }
        });
        return bundles;
    }

    public getTotalAmountOfReturnBundleProduct(productId: number) {
        let total = 0;
        this.banaan.returnBundles.forEach((bundle: ReturnBundle) => {
            const product = bundle.products.find((p: ReturnBundleProduct) => {
                return p.productId === productId;
            });
            if (product) {
                total += product.productAmount;
            }
        });
        return total;
    }

    public updateProductAmount(vReturnLine: ReturnLine, value: any = null) {
        const returnLine = this.banaan.returnLines.find((line: ReturnLine) => {
            if (vReturnLine.returnLineId) {
                return vReturnLine.returnLineId === line.returnLineId;
            }
            return vReturnLine.productId === line.productId;
        });
        if (value !== null) {
            returnLine.productAmount = value;
        }
    }

    public updateProductAmountInBundle(value: string, bundle: ReturnBundleVm, product: ReturnBundleProductVm) {
        const returnBundles = this.banaan.returnBundles.slice(0);
        const newBundles = [];
        let remaining = parseInt(value);
        this.setProductBundleAmount(bundle, product, remaining);

        returnBundles.forEach((returnBundle: ReturnBundle) => {
            if (returnBundle.productBundleId === bundle.productBundleId) {
                const returnBundleProduct = returnBundle.products.find((p: ReturnBundleProduct) => {
                    return p.productId === product.productId;
                });

                if (returnBundleProduct) {
                    const maxRemaining = this.getRemainingProductBundle(returnBundle, returnBundleProduct);
                    returnBundleProduct.productAmount = maxRemaining > remaining ? remaining : maxRemaining;
                    remaining -= returnBundleProduct.productAmount;
                }
            }

            newBundles.push(returnBundle);
        });
        this.banaan.returnBundles = newBundles;
    }

    public updateBundleAmount(value: string, bundle: ReturnBundleVm) {
        if (this.banaan.returnBundles.length <= 0) {
            throw new Error('Something went wrong, return bundles should not be empty');
        }

        const returnBundles = this.banaan.returnBundles.filter((b: ReturnBundle) => {
            return b.productBundleId === bundle.productBundleId;
        });
        returnBundles.forEach((b: ReturnBundle) => {
            b.amount = 0;
        });
        returnBundles[0].amount = parseInt(value);
    }

    public getRemainingProductBundle(returnBundle: ReturnBundle, returnBundleProduct: ReturnBundleProduct) {
        let remaining = 0;
        this.getOrderBundles().filter((b: OrderBundle) => b.productBundleId === returnBundle.productBundleId ).forEach((bundle: OrderBundle) => {
            bundle.products.filter((p: OrderBundleProduct) => {
                return p.productId === returnBundleProduct.productId;
            }).forEach((product: OrderBundleProduct) => {
                remaining += product.productAmount;
            });
        });
        this.getReturnBundles().filter((r: ReturnBundle) => r.productBundleId === returnBundle.productBundleId).forEach((bundle: ReturnBundle) => {
            bundle.products.filter((p: ReturnBundleProduct) => {
                return p.productId === returnBundleProduct.productId;
            }).forEach((product: ReturnBundleProduct) => {
                remaining -= product.productAmount;
            });
        });
        return remaining;
    }

    public getBundleProductAmount(bundle: ReturnBundleVm, product: ReturnBundleProductVm) {
        if (!this.bundleAmounts[bundle.productBundleId + '-' + product.productId]) {
            return null;
        }

        return this.bundleAmounts[bundle.productBundleId + '-' + product.productId];
    }

    public getBundleAmount(bundle: ReturnBundle) {
        return this.returnHelper.remainingBundleAmounts[bundle.productBundleId];
    }

    public calculateSquareMeterForBundle(bundle: ReturnBundleVm) {
        this.banaan.returnBundles.filter((b: ReturnBundle) => {
            return b.productBundleId === bundle.productBundleId;
        }).forEach((returnBundle: ReturnBundle) => {
            const squareMeter = this.squareMeterHelper.calculate(returnBundle);
            returnBundle.amount = squareMeter;
        });
    }

    public addAllProductsOfSite() {
        this.returnHelper.remainingProducts.forEach((product: ProductWithSerial) => {
            const amount = this.returnHelper.getRemainingProduct(product.productId);
            this.addReturnLine(product, amount);
        });

        this.returnHelper.remainingBundles.forEach((productBundle: ProductBundle) => {
            this.addReturnBundle(new ProductBundle({ productBundleId: productBundle.productBundleId }), 0);
        });
    }

    public addAllOptions(onlyProduts: boolean = false) {
        this.returnLineOptions(onlyProduts).forEach((option) => {
            if (option.type === 'product' && this.getRemainingProducts(option.product, option.product.serialNumber) > 0) {
                this.returnLineOptionSelected(option);
            } else {
                this.returnLineOptionSelected(option);
            }
        });
    }

    public getSerialOptions(productId) {
        const serials = [];
        this.returnHelper.remainingProductsWithSerial.filter((p: ProductWithSerial) => {
            return p.productId === productId && p.serialNumber;
        }).forEach((product: ProductWithSerial) => {
            serials.push(product.serialNumber);
        });
        return serials;
    }

    public isSquareMeterBundle(returnBundle: ReturnBundle) {
        return returnBundle.bundleName.toLowerCase().indexOf('vierkante meter') > -1;
    }

    private initQueryParams(query) {
        if (query.constructionSiteId) {
            this.banaan.constructionSiteId = parseInt(query.constructionSiteId);
        }

        if (query.clientId) {
            this.banaan.clientId = parseInt(query.clientId);
        }
    }

    private async loadProductsAndOrders() {
        this.isLoadingProductsAndOrders = true;
        const constructionSiteId: number = this.banaan.constructionSiteId;
        this.ordersOfSite = await this.getSiteOrders(constructionSiteId, true);
        this.returnsOfSite = (await this.getSiteReturns(constructionSiteId)).filter((banaan: Return) => {
            return banaan.returnId !== this.banaan.returnId &&
                   banaan.status === ReturnStatus.Booked;
        });
        // const buyoutsOfSite = (await this.getBuyouts()).filter((buyout: Buyout) => {
        //     return buyout.return.constructionSiteId === this.banaan.constructionSiteId;
        // });
        const buyoutsOfSite = [];

        this.squareMeterHelper =  new SquareMeterHelper();
        this.returnHelper = new ReturnHelper(this.ordersOfSite, this.returnsOfSite, buyoutsOfSite);
        this.returnHelper.compareOrdersAndReturns();
        this.isLoadingProductsAndOrders = false;
    }

    private getOrderBundles(): OrderBundle[] {
        const bundles: OrderBundle[] = [];
        this.ordersOfSite.forEach((order: Order) => {
            order.orderBundles.forEach((bundle: OrderBundle) => {
                bundles.push(bundle);
            });
        });
        return bundles;
    }

    private getReturnBundles(): ReturnBundle[] {
        const bundles: ReturnBundle[] = [];
        this.returnsOfSite.forEach((banaan: Return) => {
            banaan.returnBundles.forEach((bundle: ReturnBundle) => {
                bundles.push(bundle);
            });
        });
        return bundles;
    }

    private addReturnLine(product: ProductWithSerial, amount: number = 0) {
        if (amount === 0) {
            amount = this.banaan.isConcept || this.banaan.isBooked ?
                            this.returnHelper.getRemainingProductWithSerial(product.productId, product.serialNumber) :
                            this.returnHelper.getRemainingProduct(product.productId);
        }
        const returnLine = new ReturnLine({
            product,
            productId: product.productId,
            productAmount: amount,
            serialNumber: product.serialNumber,
            sortOrder: this.banaan.nextSortOrder,
        });
        this.banaan.returnLines.push(returnLine);
        this.updateProductAmount(returnLine);
    }

    private addReturnBundle(bundle: ProductBundle, amount: number = 0) {

        const bundlesWithProductBundleId = this.getOrderBundles().filter((orderBundle: OrderBundle) => {
            return orderBundle.productBundleId === bundle.productBundleId;
        });
        let remaining = amount > 0 ? amount : this.returnHelper.getRemainingBundleAmount(bundle.productBundleId);
        const productsRemaining = this.returnHelper.remainingObject.bundleProductAmounts[bundle.productBundleId];

        bundlesWithProductBundleId.forEach((orderBundle: OrderBundle) => {
            const bundleAmount = orderBundle.amount <= remaining ? orderBundle.amount : remaining;
            remaining -= bundleAmount;

            const returnBundle = new ReturnBundle({
                amount: bundleAmount,
                bundleName: orderBundle.bundleName,
                orderBundleId: orderBundle.orderBundleId,
                productBundleId: orderBundle.productBundleId,
                products: [],
                sortOrder: this.banaan.nextSortOrder,
            });

            orderBundle.products.forEach((product: OrderBundleProduct) => {
                const productRemaining = productsRemaining[product.productId] ? productsRemaining[product.productId] : 0;
                const productAmount = product.productAmount <= productRemaining ? product.productAmount : productRemaining;
                productsRemaining[product.productId] -= productAmount;

                if (productAmount > 0) {
                    returnBundle.products.push(new ReturnBundleProduct({
                        productId: product.productId,
                        productAmount,
                        productName: product.productName,
                        sortOrder: product.sortOrder,
                    }));
                }
            });

            returnBundle.products.sortBy('sortOrder', false);
            this.banaan.returnBundles.push(returnBundle);
        });
    }

    private setProductBundleAmount(bundle: ReturnBundleVm, product: ReturnBundleProductVm, value: any) {
        this.bundleAmounts[bundle.productBundleId + '-' + product.productId] = parseInt(value);
    }
}
