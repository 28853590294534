import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CrudCard extends Vue {
    @Prop({ type: String }) public title: string;
    @Prop({ type: Boolean }) public enableEdit: boolean;
    @Prop({ type: Boolean }) public enableAdd: boolean;
    @Prop({ type: Boolean }) public enableToggle: boolean;
    @Prop({ type: Boolean }) public enableToggleEdit: boolean;
    public visible: boolean = true;
    public editing: boolean = false;

    public edit() {
        this.$emit('edit');
    }

    public toggleEdit() {
        this.editing = !this.editing;
        this.edit();
    }

    public add() {
        this.$emit('add');
    }

    public toggle() {
        this.visible = !this.visible;
    }

    public save() {
        this.editing = false;
        this.$emit('save');
    }
}
