import { Component } from 'vue-property-decorator';
import BasePage from '@/modules/base/BasePage';
import { loginService } from '@/main';
import PwaButtonVue from '../PwaButton.vue';

@Component({
    components: {
        PwaBtn: PwaButtonVue,
    },
})
export default class NavigationBar extends BasePage {
    public get showBackButton() {
        return this.$route.meta.showBackButton;
    }

    public logout() {
        loginService.logout();
        this.$router.push({ name: 'login' });
    }

    public goBack() {
        return window.history.back();
    }

    public goto(route) {
        this.$router.push(route);
    }
}
