import Order from './Order/Order';

export default class UpdateOrderCommand {
    public order: Order;
    public createPartialDelivery: boolean;

    constructor(order: Order, createPartialDelivery: boolean) {
        this.order = order;
        this.createPartialDelivery = createPartialDelivery;
    }
}
