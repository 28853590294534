import Vue from 'vue';
import { BaseService } from './baseService';
import DamageReason from '@/models/Return/DamageReason';

export default class DamageReasonService extends BaseService {
    private endpoint: string = `${Vue.$env().serviceEndpoint}damage-reasons`;

    public getReasons() {
        return this.get(`${this.endpoint}`);
    }

    public getReason(damageReasonId: number) {
        return this.get(`${this.endpoint}/${damageReasonId}`);
    }

    public saveReason(damageReason: DamageReason) {
        if (damageReason.damageReasonId) {
            return this.put(`${this.endpoint}/${damageReason.damageReasonId}`, damageReason);
        } else {
            return this.post(`${this.endpoint}`, damageReason);
        }
    }

    public deleteReason(damageReason: DamageReason) {
        return this.delete(`${this.endpoint}/${damageReason.damageReasonId}`);
    }
}
