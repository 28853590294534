import SerialNumber, { SerialNumberStatus } from '@/modules/inventory/models/Product/SerialNumber';
import ProductRepository from '@/modules/inventory/repo/ProductRepository';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ProductStore } from '../../models/ProductStore';
import SelectedSerialNumber from '../../models/SelectedSerialNumber';

@Component
export default class SerialNumberSelector extends Vue {
    @Prop() public productId: number;
    @Prop() public value: string;
    @Prop({ type: Boolean, default: true }) public isValid: boolean;
    @Prop({ type: Array }) public selected: SelectedSerialNumber[];
    public repository: ProductRepository;
    public loading: boolean = false;
    public serialNumbers: SerialNumber[] = [];

    public get current(): SerialNumber {
        return this.serialNumbers.find((s: SerialNumber) => {
            return s.serialNumber === this.value;
        });
    }

    public async mounted() {
        this.loading = true;
        this.repository = new ProductRepository({
            primaryValue: this.productId,
            args: { store: ProductStore.Rental },
        });
        this.serialNumbers = await this.repository.getSerialNumbers(new Date());
        this.loading = false;
    }

    public get options(): SerialNumber[] {
        return this.serialNumbers.filter((s: SerialNumber) => {
            if (this.selected && this.selected.length) {
                const index = this.selected.findIndex((selectedSerial) => {
                    return selectedSerial.productId === this.productId &&
                        selectedSerial.serialNumber === s.serialNumber;
                });
                if (index > -1 && s.serialNumber !== this.value) {
                    return false;
                }
            }
            return s.rentalStatus === SerialNumberStatus.Available;
        });
    }

    public serialNumberSelected(serialNumber: SerialNumber) {
        this.$emit('input', serialNumber ? serialNumber.serialNumber : null);
        this.$emit('select', serialNumber);
    }
}
