import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class PwaUpdateButton extends Vue {
    public registered: boolean = false;
    public downloadingUpdate: boolean = false;
    public canUpdate: boolean = false;

    public get visible() {
        return this.registered && (this.downloadingUpdate || this.canUpdate);
    }

    public mounted() {
        document.addEventListener('pwa:registered', this.onRegisterd);
        document.addEventListener('pwa:updatefound', this.onUpdateFound);
        document.addEventListener('pwa:updated', this.onUpdate);
    }

    public beforeDestroy() {
        document.removeEventListener('pwa:registered', this.onRegisterd);
        document.removeEventListener('pwa:updatefound', this.onUpdateFound);
        document.removeEventListener('pwa:updated', this.onUpdate);
    }

    public refresh() {
        window.location.reload();
    }

    private onRegisterd() {
        this.registered = true;
    }

    private onUpdateFound() {
        this.downloadingUpdate = true;
    }

    private onUpdate() {
        this.downloadingUpdate = false;
        this.canUpdate = true;
    }
}
