import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component
export default class App extends Vue {
    @Watch('$route')
    public routeChanged() {
        if (this.$route.query.banaan) {
            this.$theme.setTheme('Dark');
        }
    }
}
