import OrderLine from './OrderLine';
import ConstructionSite from '../ConstructionSite/ConstructionSite';
import Client from '../../modules/client/models/Client';
import ClientContact from '@/modules/client/models/ClientContact';
import OrderStatus, { OrderStatusEnum } from './OrderStatus';
import { OrderTypeEnum } from './OrderType';
import moment from 'moment';
import OrderBundle from './OrderBundle';
import List from '@/prototypes/List';
import OrderBundleProduct from './OrderBundleProduct';
import Transporter from '../Transport/Transporter';
import VirtualOrderLine from './VirtualOrderLine';
import DeliveryAddress from '../../modules/client/models/Address';

export default class Order {
    public orderId?: number = null;
    public orderType: OrderTypeEnum = OrderTypeEnum.Unknown;
    public deliveryDate: Date = null;
    public deliveryAddressId?: number = null;
    public deliveryAddress?: DeliveryAddress = null;
    public deleted = false;
    public isPartialDelivery: boolean = false;
    public priority: string;
    public constructionSiteId: number = null;
    public constructionSite: ConstructionSite = null;
    public clientId: number = null;
    public client: Client = null;
    public contactId: number = null;
    public contact: ClientContact = null;
    public discount: number = 0;
    public transportCosts: number = 0;
    public transportHours: number = 0;
    public status: OrderStatusEnum = OrderStatusEnum.Unknown;
    public statusText?: string = '';
    public orderLines: OrderLine[] = [];
    public orderBundles: OrderBundle[] = [];
    public orderConfirmations: Array<{ orderConfirmationId: number }> = [];
    public internalNote: string = '';
    public externalNote: string = '';
    public invoiceNote: string = '';
    public parentOrderId?: number = null;
    public parentOrder?: Order = null;
    public transporterId?: number = null;
    public transporter?: Transporter = new Transporter();
    public transportByCustomer: boolean = false;
    public transportDescription: string = '';
    public bookedDate: Date = null;
    public quotationId?: number = null;
    public returnToYard = false;
    public registrationDate?: Date = null;
    public packingSlipMemo: string = '';
    public waitingTime: number = 0;
    public created?: string = '';
    public serviceReportId?: number = null;

    constructor(obj?: Partial<Order>) {
        if (obj) {
            Object.assign(this, obj);
        }

        this.deliveryDate = this.deliveryDate ? moment(this.deliveryDate).toDate() : null;
        if (this.status === OrderStatusEnum.Unknown) {
            this.status = OrderStatusEnum.Saved;
        }
    }

    public getStatusText() {
        return OrderStatus.getStatus(this.status);
    }

    public isSaved() {
        return this.status === OrderStatusEnum.Saved;
    }

    public isConcept() {
        return this.status === OrderStatusEnum.Concept;
    }

    public isBooked() {
        return this.status === OrderStatusEnum.Booked;
    }

    public get isSalesOrder() {
        return this.orderType === OrderTypeEnum.SalesOrder;
    }

    public hasProductsOrBundles() {
        return this.orderLines.length > 0 || this.orderBundles.length > 0;
    }

    public total() {
        const list = new List(this.orderLines);
        return list.sum((orderLine: OrderLine) => orderLine.productAmount * orderLine.productPrice);
    }

    public get deviantOrderLines(): OrderLine[] {
        return this.orderLines.filter((orderLine: OrderLine) => {
            return orderLine.orderedAmount !== orderLine.productAmount;
        });
    }

    public get deviantOrderBundles(): OrderBundle[] {
        return this.orderBundles.filter((orderBundle: OrderBundle) => {
            return orderBundle.amount !== orderBundle.orderedAmount;
        });
    }

    public get deviantOrderBundleProducts(): OrderBundleProduct[] {
        const products = [];
        this.orderBundles.forEach((orderBundle: OrderBundle) => {
            orderBundle.products.forEach((product: OrderBundleProduct) => {
                if (product.orderedAmount !== product.productAmount) {
                    products.push(product);
                }
            });
        });

        return products;
    }

    public get notFullyDelivered(): boolean {
        const remaining = this.deviantOrderLines.length +
                          this.deviantOrderBundles.length +
                          this.deviantOrderBundleProducts.length;

        return remaining > 0;
    }

    public get totalTransportCosts() {
        return this.transportCosts * this.transportHours;
    }

    public get nextSortOrder() {
        const first = [...this.orderedLinesAndBundles].sortBy('sortOrder', true).shift();
        return first ? first.sortOrder + 1 : 1;
    }
    public get orderLinesWithSerial() {
        return this.orderLines.filter((line: OrderLine) => {
            return line.product && line.serialNumber;
        });
    }

    public get hasProductsWithSerial() {
        return this.orderLinesWithSerial.length > 0;
    }

    public get orderedLinesAndBundles() {
        const lines: VirtualOrderLine[] = [];
        this.orderLines.forEach((orderLine: OrderLine) => {
            lines.push(new VirtualOrderLine(orderLine, null));
        });

        this.orderBundles.forEach((orderBundle: OrderBundle) => {
            lines.push(new VirtualOrderLine(null, new OrderBundle(orderBundle)));
        });
        return lines.sortBy('sortOrder', false);
    }
}
