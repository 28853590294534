import Vue from 'vue';
import Router, { Route } from 'vue-router';
import { UserRole } from './models/UserRole';
import AccountHelper from './helpers/AccountHelper';
import AdminRoutes from './router/admin';
import StaffRoutes from './modules-mobile/routes';
import anonymousRoutes from './router/anonymous';
import { loginService } from './main';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    routes: [
        ...anonymousRoutes,
        AdminRoutes,
        StaffRoutes,
    ],
});

const userHasAtLeastOneRole = (roles, userRoles) => {
    let access = false;
    roles.forEach((role: UserRole) => {
        if (userRoles.indexOf(role) > -1) {
            access = true;
        }
    });
    return access;
};

router.beforeEach((to: Route, from: Route, next: any) => {
    const anonymous = to.meta && !!to.meta.anonymous;
    if (anonymous) {
        next();
        return;
    }

    if (loginService.isLoggedIn()) {
        const accessRoles = to.meta.roles || ([] as UserRole[]);
        if (accessRoles && accessRoles.length > 0 && !userHasAtLeastOneRole(accessRoles, AccountHelper.getRoles())) {
            next({ name: 'not-found' });
        }

        document.title = to.meta.title;
        next();
        return;
    }

    next(`/inloggen?redirect=${to.path}`);
});

export default router;
