import Vue from 'vue';
import App from './App.vue';
import router from './router';
import SharedUI from '@prd/shared-ui';
import { LoginService, EnvPlugin } from '@prd/shared-core/dist/services';
import moment from 'moment';
import Vuelidate from 'vuelidate';
import VueAppInsights from 'vue-application-insights';
import '@/prototypes/Array';
import '@/Filters';
import './registerServiceWorker';

moment.locale('nl');

Vue.use(EnvPlugin);
Vue.use(Vuelidate);
Vue.use(SharedUI, {
    datepicker: { disableWeekends: true },
    installBootstrapVue: true,
});

Vue.use(VueAppInsights, {
  id: Vue.$env().applicationInsightId,
  router,
});

import './components/index';

export const loginService = new LoginService({
    endpoint: Vue.$env().loginEndpoint,
    localStorageKey: 'uva-auth',
    unAuthorizedHandler: () => {
        router.push({ name: 'login' });
    },
});

Vue.config.productionTip = false;
new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');
