import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Client from '@/modules/client/models/Client';
import BasePage from '@/modules/base/BasePage';
import to from 'await-to-js';
import ClientContact from '@/modules/client/models/ClientContact';
import AddressHelper from '@/helpers/AddressHelper';

@Component
export default class ClientEditComponent extends BasePage {
    @Prop({ default: () => new Client() }) public client: Client;
    @Prop({ default: false }) public editMode: boolean;
    @Prop({ default: null }) public onSave: (err: string, res: Client) => void;

    public selectedClientContact: ClientContact = null;
    public showEditClientContact: boolean = false;
    public clientWithDebtor: Client = new Client();
    public isLoadingAddress: boolean = false;

    public mounted() {
        if (this.editMode && !this.client.clientId && this.client.contacts.length === 0) {
            this.createClientContact();
        }
    }

    public createClientContact() {
        const client = new ClientContact();
        this.client.contacts.push(client);
        this.editClientContact(client);
    }

    public saveClientContact() {
        this.selectedClientContact = null;
        this.save();
    }

    public editClientContact(client) {
        this.selectedClientContact = client;
        this.showEditClientContact = true;
    }

    public deleteClientContact(index) {
        this.client.contacts.splice(index, 1);
        this.cancelEditClientContact();
    }

    public async save(): Promise<Client> {
        if (!this.client.name) {
            this.clearAndShowError('Het is verplicht om een naam op te geven van een klant.');
            return null;
        }

        if (await this.debtorExists()) {
            this.clearAndShowError('Er betstaat al een klant met dit debiteur nummer.');
            return null;
        }

        this.cancelEditClientContact();
        this.showPending('Klant gegevens worden opgeslagen.');
        const [err, response] = await to(this.clientService.saveClient(this.client));
        if (err || !response) {
            this.clearAndShowError('Mislukt om klant gegevens op te slaan.', err);
            return null;
        }

        const client = response.data as Client;
        this.clearAndShowSuccess('Klant gegevens succesvol opgeslagen.');
        return client;
    }

    public async autoCompleteAddress() {
        if (this.client.invoicePostalCode) {
            this.isLoadingAddress = true;
            const address = await AddressHelper.find(this.client.invoicePostalCode);
            if (address) {
                this.client.invoiceAddressLine1 = address.Street;
                this.client.invoiceAddressLine2 = address.ZipCode + ' ' + address.City;
                this.client.invoiceCity = address.City;
            }
            this.isLoadingAddress = false;
        }
    }

    public async checkDuplicateDebtor() {
        this.debtorExists();
    }

    private cancelEditClientContact() {
        this.selectedClientContact = null;
        this.showEditClientContact = false;
    }

    private async debtorExists() {
        if (this.client.clientId < 1) {
            this.clientWithDebtor = await this.getClientByDebtorNr(this.client.debtorNumber);
            if (this.clientWithDebtor && this.clientWithDebtor.clientId) {
                return true;
            }
        }
        return false;
    }

    private async getClientByDebtorNr(debtorNumber: string): Promise<Client> {
        const clients = await this.getClients();
        debtorNumber = debtorNumber ? debtorNumber.trim().toLocaleLowerCase() : '';
        return clients.find((client: Client) => {
            if (client.debtorNumber) {
                return client.debtorNumber.trim().toLocaleLowerCase() === debtorNumber;
            }
        });
    }
}
