import Stock from '../../../models/Stock';
import LedgerAccount from '../../../models/LedgerAccount';
import { ProductType } from './ProductType';
import { ProductDayPriceSource } from './ProductDayPriceSource';

export default class Product {
    public articleCode: string = '';
    public averagePurchasePrice: number = 0;
    public productId: number = 0;
    public name: string = '';
    public description: string = '';
    public length: number = 0;
    public width: number = 0;
    public height: number = 0;
    public weight: number = 0;
    public productGroupId: number = 0;
    public productGroupName: string = '';
    public dayPrice: number = 0;
    public purchasePrice: number = 0;
    public ledgerAccountId: number = 0;
    public ledgerAccount: LedgerAccount = new LedgerAccount();
    public sortOrder: number = 0;
    public productType: ProductType = ProductType.Product;
    public productCategory: string = '';
    public dayPriceSource: ProductDayPriceSource = ProductDayPriceSource.OriginalPrice;
    public internalNote: string = '';
    public serialNumbers: string[] = [];
    public serialNumber: string = '';
    public buyoutPrice: number = 0;
    public icons: string = '';
    public marketPrice: number = 0;
    public salesMargin: number = 0;
    public marketPriceDate: string | Date;
    public targetRate: number = 0;
    public lastInventoryCheckDate: string = '';

    constructor(object?: Partial<Product>) {
        if (object) {
            Object.assign(this, object);
        }
    }

    public get isProduct() {
        return this.productType === ProductType.Product;
    }

    public get isService() {
        return this.productType === ProductType.Service;
    }

    public get productTypeName() {
        switch (this.productType) {
            case ProductType.Product:
                return 'Product';
            case ProductType.Service:
                return 'Dienst';
            default:
                return 'Onbekend';
        }
    }

    public get dayPriceSourceName() {
        switch (this.dayPriceSource) {
            case ProductDayPriceSource.ClientDiscount:
                return 'klantprijs';
            case ProductDayPriceSource.PreviousOrder:
                return 'laatste order';
            default:
                return 'catalogusprijs';
        }
    }

    public get targetPrice() {
        return (this.marketPrice * (100 + this.salesMargin)) / 100;
    }

    public get marketPriceOutOfDate() {
        const timeAgo = new Date().getTime() - new Date(this.marketPriceDate).getTime();
        const daysAgo = Math.ceil(timeAgo / 1000 / 3600 / 24);
        return daysAgo > 30;
    }
}
