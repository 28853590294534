import ConstructionSite from '@/models/ConstructionSite/ConstructionSite';
import ClientContact from '@/modules/client/models/ClientContact';

export default class Client {
    public clientId: number = 0;
    public name: string = '';
    public coCNumber: string = '';
    public debtorNumber: string = '';
    public internalMemo: string = '';
    public invoicePostalCode: string = '';
    public invoiceAddressLine1: string = '';
    public invoiceAddressLine2: string = '';
    public invoiceCity: string = '';
    public emailAddress: string = '';
    public phoneNumber: string = '';
    public deleted: boolean = false;

    public constructionSites: ConstructionSite[] = [];
    public contacts: ClientContact[] = [];
    public addresses: any = [];

    public constructor(object: any = {}) {
        Object.assign(this, object);
    }
}
