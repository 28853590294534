import { UserRole } from '@/models/UserRole';
import BaseLayout from './BaseLayout.vue';

export default {
    path: '/medewerker',
    meta: { title: 'UVA - Medewerker', roles: [UserRole.Member] },
    component: BaseLayout,
    children: [
        {
            name: 'picking-slip-dashboard',
            alias: 'staff-dashboard',
            path: '',
            component: () => import(/* webpackChunkName: "staff" */ './picking-slip/views/PickingSlipDashboard.vue'),
            meta: { title: 'UVA - pakbonnen', roles: [UserRole.Member] },
        },
        {
            name: 'picking-slip',
            path: 'pakbon/:jobId/:deliveryType',
            component: () => import(/* webpackChunkName: "staff" */ './picking-slip/views/PickingSlip.vue'),
            meta: { title: 'UVA - pakbon', roles: [UserRole.Member] },
        },
        {
            name: 'solve-issue',
            path: 'issue-oplossen/:jobId/:deliveryType',
            component: () => import(/* webpackChunkName: "staff" */ './picking-slip/views/SolveIssue.vue'),
            meta: { title: 'UVA - pakbon', roles: [UserRole.Member] },
        },
    ],
};
