import OrderLine from './OrderLine';
import OrderBundle from './OrderBundle';

export default class VirtualOrderLine {
    public orderLine?: OrderLine = null;
    public orderBundle?: OrderBundle = null;
    public sortOrder: number = 0;

    constructor(orderLine?: OrderLine, orderBundle?: OrderBundle) {
        this.orderLine = orderLine;
        this.orderBundle = orderBundle;
        this.sortOrder = orderLine ? orderLine.sortOrder : orderBundle.sortOrder;
    }

    public get isLine() {
        return !!this.orderLine;
    }

    public get isBundle() {
        return !!this.orderBundle;
    }
}
