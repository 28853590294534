import { Component, Prop } from 'vue-property-decorator';
import Order from '@/models/Order/Order';
import BaseComponentWithValidations from '../BaseComponentWithValidations';
import { OrderStatusEnum } from '@/models/Order/OrderStatus';

@Component
export default class RentalCreateComponent extends BaseComponentWithValidations {
    @Prop({ default: () => new Order() }) public order: Order;
    @Prop({ default: false }) public toBooked: boolean;

    public async mounted() {
        this.order.orderBundles.sortBy('sortOrder', false);
        this.order.orderLines.sortBy('sortOrder', false);
        this.$watch('order', this.watchOrder, { immediate: true });
    }

    private watchOrder() {
        this.order.clientId = this.order.client ? this.order.client.clientId : null;
        this.order.constructionSiteId = this.order.constructionSite ? this.order.constructionSite.constructionSiteId : null;
        this.order.contactId = this.order.contact ? this.order.contact.clientContactId : null;

        if (!this.order.status) {
            this.order.status = OrderStatusEnum.Unknown;
        }
    }
}
