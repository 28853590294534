import { Component, Prop } from 'vue-property-decorator';
import ConstructionSite from '@/models/ConstructionSite/ConstructionSite';
import Client from '@/modules/client/models/Client';
import moment from 'moment';
import to from 'await-to-js';
import ClientContact from '@/modules/client/models/ClientContact';
import BaseComponentWithValidations from '../BaseComponentWithValidations';
import AddressHelper from '@/helpers/AddressHelper';
import Order from '@/models/Order/Order';
import { required } from 'vuelidate/lib/validators';

@Component({
    validations: {
        site: {
            name: { required },
        },
    },
})
export default class SiteEditComponent extends BaseComponentWithValidations {
    @Prop({ default: () => new ConstructionSite() }) public site: ConstructionSite;
    @Prop({ default: () => [] }) public clients: Client[];
    @Prop({ default: false }) public editMode: boolean;
    @Prop({ default: null }) public order: Order;

    public estimatedEndDate: Date = null;
    public findingAddress: boolean = false;
    public selectedContacts = [];

    public mounted() {
        this.estimatedEndDate = moment(this.site.estimatedEndDate).toDate();
        if (this.order && this.order.client) {
            this.site.client = this.order.client;
            this.site.clientId = this.order.client.clientId;
        }

        this.site.contacts.forEach((c: ClientContact) => {
            this.selectedContacts.push(c.clientContactId);
        });
    }

    public get contacts() {
        return this.site.client ? this.site.client.contacts : [];
    }

    public getClientName(clientId: number): string {
        const client: Client = this.clients.find((c) => {
            return c.clientId === clientId;
        });

        return client ? client.name : '';
    }

    public getContactName(contact: ClientContact): string {
        const clientContact = new ClientContact(contact);
        return clientContact.name();
    }

    public updateSiteEndDate() {
        this.site.estimatedEndDate = moment(this.estimatedEndDate).format('YYYY-MM-DD');
    }

    public clientChanged(client: Client) {
        this.site.clientId = client.clientId;
    }

    public contactChanged(contactIds: number[]) {
        const contacts = [];
        contactIds.forEach((clientContactId: number) => {
            contacts.push(this.contacts.find((contact: ClientContact) => {
                return contact.clientContactId === clientContactId;
            }));
        });
        this.site.contacts = contacts;
    }

    public async save(): Promise<ConstructionSite> {
        this.$v.site.$touch();
        if (this.$v.site.$invalid) {
            this.setValidations(this.$v);
            this.clearAndShowError('Werk kan niet opgeslagen worden, zorg dat alle verplichte velden zijn ingevuld.');
            return null;
        }

        this.showPending('Werk opslaan...');
        delete this.site.client;
        const [err, response] = await to(this.siteService.saveSite(this.site));
        if (err || !response) {
            this.clearAndShowError('Mislukt om dit werk op te slaan.', err);
            return null;
        }

        this.clearAndShowSuccess('Werk is succesvol opgeslagen.');
        return new ConstructionSite(response.data);
    }

    public async findAddress() {
        this.findingAddress = true;
        const address = await AddressHelper.find(this.site.address.postalCode);
        this.findingAddress = false;

        if (address) {
            this.site.address.addressLine1 = address.Street;
            this.site.address.addressLine2 = `${address.ZipCode} ${address.City}`;
            this.site.address.city = address.City;
        }
    }

    public clientLabel(client: Client) {
        return client.deleted ? `${client.name} (gearchiveerd)` : client.name;
    }
}
