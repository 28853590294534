import Vue from 'vue';
import Component from 'vue-class-component';
import MobileHeaderVue from './components/MobileHeader.vue';

@Component({
    components: {
        MobileHeader: MobileHeaderVue,
    },
    beforeRouteUpdate(to, from, next) {
        const deep = Object.keys(to.params).length > 0;
        if (deep) {
            this.transition = 'right-left';
        } else {
            this.transition = 'left-right';
        }
        next();
    },
})
export default class BaseLayout extends Vue {
    public transition = 'left-right';

    public mounted() {
        const html = document.querySelector('html');
        html.classList.add('prd-mobile');
    }

    public beforeDestroy() {
        const html = document.querySelector('html');
        html.classList.remove('prd-mobile');
    }
}
