import Product from '@/modules/inventory/models/Product';
import { ProductCategory } from '@/modules/inventory/models/ProductCategory';
import { ProductType } from '@/modules/inventory/models/ProductType';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ProductLink extends Vue {
    @Prop() public product: Product;
    @Prop() public serialNumber: string;
    @Prop() public articleCode: string;
    @Prop({ type: String, default: '_self' }) public target: string;
    @Prop({ type: Boolean }) public showInternalNote: boolean;
    @Prop({ default: ProductCategory.All }) public category: ProductCategory;

    public get icon() {
        const icons = {
            [ProductType.Product]: 'shopping-bag',
            [ProductType.Part]: 'link',
            [ProductType.Service]: 'tool',
        };

        return icons[this.product.productType];
    }

    public get to() {
        return {
            name: 'product',
            params: {
                productId: this.product.productId,
            },
            query: {
                product_category: this.category,
            },
        };
    }
}
