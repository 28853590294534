export default class Address {
    public city: string = '';
    public postalCode: string = '';
    public addressLine1: string = '';
    public addressLine2: string = '';

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
