import Product from '../../modules/inventory/models/Product';

export default class DamagedMissingProduct {
    public productId: number = 0;
    public product: Product = new Product();
    public failureAmount: number = 0;
    public amountDamaged: number = 0;
    public priceDamaged: number = 0;
    public amountMissing: number = 0;
    public priceMissing: number = 0;
    public description: string = '';

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
