import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class GridBadge extends Vue {
    @Prop({ default: null }) public text: string;
    @Prop({ default: null }) public variant: string;
    @Prop({ default: null }) public icon: string;
    @Prop({ default: null }) public tooltipText: string;
}
