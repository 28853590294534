import ReturnBundleProductVm from './ReturnBundleProductVm';

export default class ReturnBundleVm {
    public productBundleId: number = 0;
    public bundleName: string = '';
    public amount: number = 0;
    public remaining: number = 0;
    public products: ReturnBundleProductVm[] = [];
    public sortOrder: number = 0;

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
