import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class GridTooltipComponent extends Vue {
    @Prop() public tooltip: string;
    @Prop() public icon: string;
    @Prop() public text: string;
}
