import Vue from 'vue';
import { BaseService } from './baseService';
import Client from '@/modules/client/models/Client';
import RentalService from './RentalService';
import DiscountTable from '@/models/DiscountTable';
import to from 'await-to-js';
import ProductOnSite from '@/modules/inventory/models/ProductOnSite';
import DeliveryAddress from '@/modules/client/models/Address';

export default class ClientService extends BaseService {
    private endpoint = `${Vue.$env().serviceEndpoint}clients/`;
    private rentalService: RentalService = new RentalService();

    public getClients(isDeleted: boolean = false) {
        if (isDeleted) {
            return this.get(`${this.endpoint}?isDeleted=${isDeleted}`);
        }

        return this.get(`${this.endpoint}`);
    }

    public getClient(clientId: number) {
        return this.get(`${this.endpoint}${clientId}`);
    }

    public saveClient(client: Client) {
        if (client.clientId <= 0) {
            delete client.clientId;
        }
        return this.post(`${this.endpoint}`, client);
    }

    public getOrders(clients: number[], onlyBookedOrders: boolean = false) {
        return this.rentalService.getOrdersOfClients(clients, onlyBookedOrders);
    }

    public deleteClient(client: Client) {
        return this.delete(`${this.endpoint}/${client.clientId}`);
    }

    public sendRentalOverview(clientId: number, rentalOverview) {
        return this.post(`${this.endpoint}${clientId}/send-rental-overview`, rentalOverview);
    }

    public async getProducts(clientId: number): Promise<ProductOnSite[]> {
        const [err, response] = await to(this.get(`${this.endpoint}${clientId}/inventory`));
        return !err ? response.data.items as ProductOnSite[] : null;
    }

    public async getDeliveryAddresses(clientId: number) {
        const [err, response] = await to(this.get(`${this.endpoint}${clientId}/delivery-addresses`));
        return !err ? response.data : null;
    }

    public async saveDeliveryAddress(clientId, address: DeliveryAddress) {
        let err = null;
        if (address.deliveryAddressId > 0) {
            [err] = await to(this.put(`${this.endpoint}${clientId}/delivery-addresses/${address.deliveryAddressId}`, address ));
        } else {
            [err] = await to(this.post(`${this.endpoint}${clientId}/delivery-addresses`, address ));
        }
        return !err;
    }

    public async deleteDeliveryAddress(clientId: number, addressId: number) {
        const [err] = await to(this.delete(`${this.endpoint}${clientId}/delivery-addresses/${addressId}`));
        return !err;
    }
}
