import { UserRole } from '@/models/UserRole';
import BaseView from '@/modules/base/Base.vue';

export default {
    path: '/magazijn-beheer',
    name: 'inventory-management',
    component: BaseView,
    meta: { page: 'Magazijn', roles: [UserRole.Stock] },
    redirect: { name: 'products' },
    children: [
        {
            path: 'producten',
            name: 'products',
            component: () => import(/* webpackChunkName: "products.overview" */ './views/ProductsOverview.vue'),
            meta: {
                title: 'UVA - Magazijn',
                page: 'Producten',
                roles: [UserRole.Stock],
            },
        },
        {
            path: 'producten/:productId',
            name: 'product',
            component: () => import(/* webpackChunkName: "products.detail" */ './views/ProductView.vue'),
            meta: {
                title: 'UVA - Magazijn',
                page: 'Product',
                roles: [UserRole.Stock],
                showBackButton: true,
            },
        },
        {
            path: 'product-groepen',
            name: 'product-groups',
            component: () => import(/* webpackChunkName: "product-groups.overview" */ './views/ProductGroupsOverview.vue'),
            meta: { title: 'UVA - Product groepen', page: 'Product groepen', roles: [UserRole.Stock] },
        },
        {
            path: 'product-groepen/:groupId',
            name: 'product-group',
            component: () =>
            import(/* webpackChunkName: "product-groups.detail" */ './views/ProductGroupView.vue'),
            meta: { title: 'UVA - Product groepen', page: 'Product groepen', roles: [UserRole.Stock] },
        },
        {
            path: 'product-bundels',
            name: 'product-bundles',
            component: () =>
                import(/* webpackChunkName: "product-bundles.overview" */ './views/ProductBundlesOverview.vue'),
            meta: { title: 'UVA - Product bundels', page: 'Product bundels', roles: [UserRole.Stock] },
        },
        {
            path: 'product-bundels/aanmaken',
            name: 'create-product-bundle',
            component: () => import(/* webpackChunkName: "product-bundles.detail" */ './views/ProductBundleCreate.vue'),
            meta: {
                title: 'UVA - Product bundel aanmaken',
                page: 'Product bundel aanmaken',
                roles: [UserRole.Stock],
                showBackButton: true,
            },
        },
        {
            path: 'product-bundles/:productBundleId',
            name: 'product-bundle',
            component: () => import(/* webpackChunkName: "product-bundles.detail" */ './views/ProductBundleEdit.vue'),
            meta: { title: 'UVA - Product bundel', page: 'Product bundel', roles: [UserRole.Stock], showBackButton: true },
        },
        {
            path: 'diensten',
            name: 'services',
            component: () => import(/* webpackChunkName: "services.overview" */ './views/ServicesOverview.vue'),
            meta: { title: 'UVA - Diensten', page: 'Diensten', roles: [UserRole.Stock] },
        },
        {
            path: 'schades',
            name: 'damage-reasons',
            component: () => import(/* webpackChunkName: "damage-reasons.overview" */ './views/DamageReasonsOverview.vue'),
            meta: { title: 'UVA - Schades', page: 'Schades', roles: [UserRole.Stock] },
        },
        {
            path: 'schades/:damageReasonId',
            name: 'damage-reason',
            component: () => import(/* webpackChunkName: "damage-reasons.detail" */ './views/DamageReasonView.vue'),
            meta: { title: 'UVA - Schade', page: 'Schade', roles: [UserRole.Stock], showBackButton: true },
        },
        {
            path: 'grootboekrekeningen',
            name: 'ledger-accounts',
            component: () =>
                import(/* webpackChunkName: "ledger-accounts.overview" */ './views/LedgerAccountsOverview.vue'),
            meta: { title: 'UVA - Grootboek rekeningen', page: 'Grootboekrekeningen', roles: [UserRole.Stock] },
        },
        {
            path: 'onderdelen',
            name: 'parts',
            component: () => import(/* webpackChunkName: "parts" */ './views/PartsOverview.vue'),
            meta: {
                title: 'UVA - Magazijn',
                page: 'Onderdelen',
                roles: [UserRole.Stock],
            },
        },
        {
            path: 'onderdelen/:productId',
            name: 'part',
            component: () => import(/* webpackChunkName: "parts" */ './views/ProductView.vue'),
            meta: {
                title: 'UVA - Magazijn',
                page: 'Onderdelen',
                roles: [UserRole.Stock],
            },
        },
        {
            path: 'switch-voorraad',
            name: 'switch-inventory',
            component: () => import(/* webpackChunkName: "switch" */ './views/SwitchInventory.vue'),
            meta: {
                title: 'UVA - Magazijn',
                page: 'Switch voorraad',
                roles: [UserRole.Stock],
            },
        },
    ],
};
