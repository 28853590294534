import { UserRole } from '@/models/UserRole';

const routes = [
    {
        path: '/inloggen',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
        meta: {
            title: 'UVA - Inloggen ',
            page: 'Inloggen',
            roles: [UserRole.RentalUser, UserRole.SalesUser, UserRole.SiteAdmin, UserRole.Stock, UserRole.Logistics, UserRole.ClientManagement],
        },
    },
    {
        path: '/uitnodiging/:key',
        name: 'invite',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Invite.vue'),
        meta: { title: 'Team - Invite' },
    },
    {
        path: '/not-found',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "notfound" */ '@/views/NotFound.vue'),
        meta: {
            title: 'UVA - huh? ',
            page: 'Niet gevonden',
            roles: [UserRole.RentalUser, UserRole.SalesUser, UserRole.SiteAdmin, UserRole.Stock, UserRole.Logistics, UserRole.ClientManagement],
            showBackButton: true,
        },
    },
    {
        path: '/afkoopvoorstel/:buyoutId',
        name: 'buyout-proposal',
        component: () => import(/* webpackChunkName: "buyout.proposal" */ '@/modules/rental/views/buyout/Proposal.vue'),
        meta: { title: 'UVA - Afkoopvoorstel' },
    },
    {
        name: 'quotation-approval',
        path: '/offertes/:quotationId',
        component: () => import(/* webpackChunkName: "quotation.approval" */ '@/modules/sales/views/QuotationApproval.vue'),
        meta: { title: 'UVA - Offerte' },
    },
    {
        name: 'order-confirmation-proposal',
        path: '/opdracht-bevestiging/:orderId',
        component: () => import(/* webpackChunkName: "order-confirmations.proposal" */ '@/modules/sales/views/OrderConfirmationProposal.vue'),
        meta: { title: 'UVA - Opdrachtbevestiging' },
    },
];

routes.map((r) => {
    (r.meta as any).anonymous = true;
    return r;
});

export default routes;
