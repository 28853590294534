interface IRepositoryContext {
    primaryValue?: string | number;
    args?: any;
}

export default class RepositoryContext {
    public primaryValue?: string | number = null;
    public args?: any = null;

    constructor(obj: IRepositoryContext = {}) {
        Object.assign(this, obj);
    }
}
