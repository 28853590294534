import Product from '@/modules/inventory/models/Product';
import ProductOnSite from '@/modules/inventory/models/ProductOnSite';
import Return from '@/models/Return/Return';
import ReturnLine from '@/models/Return/ReturnLine';
import BasePage from '@/modules/base/BasePage';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ReturnProductWithSerial extends BasePage {
    @Prop({ type: Object, default: new Return() }) public banaan: Return;
    @Prop({ type: Boolean, default: false }) public editable: boolean;
    public serialNumbersPerProductId: any = {};
    public isLoading: boolean = true;
    public newReturnLines = [];
    public invalidSerialNumbers: boolean = false;

    public async mounted() {
        await this.initNewReturnLines();
        this.isLoading = false;
    }

    public get availableSerials() {
        return (productId) => {
            if (this.serialNumbersPerProductId && this.serialNumbersPerProductId[productId]) {
                return this.serialNumbersPerProductId[productId].filter((serialNumber: string) => {
                    const index = this.newReturnLines.findIndex((r: ReturnLine) => r.serialNumber === serialNumber);
                    return index === -1;
                });
            }
            return this.serialNumbersPerProductId && this.serialNumbersPerProductId[productId] ? this.serialNumbersPerProductId[productId] : [];
        };
    }

    public async initNewReturnLines() {
        this.newReturnLines = this.getReturnLines();
        const inventory = await this.getInventory();
        for (let i = 0; i < this.newReturnLines.length; i++) {
            const line = this.newReturnLines[i] as ReturnLine;
            if (line.product && this.productHasSerial(line.product)) {
                const serials = [];
                inventory.forEach((onSite: ProductOnSite) => {
                    if (onSite.product && onSite.product.productId === line.productId && onSite.product.serialNumber) {
                        serials.push(onSite.product.serialNumber);
                    }
                });
                this.serialNumbersPerProductId[line.productId] = serials;

                if (line.serialNumber && serials.indexOf(line.serialNumber) < 0) {
                    line.serialNumber = null;
                }
            }
        }
    }

    public splitReturnLines() {
        this.invalidSerialNumbers = false;
        if (this.newReturnLines.length) {
            this.banaan.returnLines = this.banaan.returnLines.filter((returnLine) => {
                return !this.productHasSerial(returnLine.product);
            });
            this.banaan.returnLines.push(...this.newReturnLines);
        }
        this.newReturnLines.forEach((returnLine: ReturnLine) => {
            if (!returnLine.serialNumber) {
                this.invalidSerialNumbers = true;
            }
        });

        return this.invalidSerialNumbers ? false : true;
    }

    private getReturnLines() {
        const list = [];
        this.banaan.returnLines.filter((returnLine: ReturnLine) => {
            return this.productHasSerial(returnLine.product);
        }).forEach((returnLine: ReturnLine) => {
            for (let i = 0; i < returnLine.productAmount; i++) {
                const line = { ...returnLine };
                line.productAmount = 1;
                delete line.returnLineId;
                list.push(line);
            }
        });
        return list;
    }

    private async getInventory() {
        const inventory = await this.getSiteProducts(this.banaan.constructionSiteId);
        return inventory.filter((onSite: ProductOnSite) => {
            return onSite.rentedOut + onSite.toVerify > 0;
        });
    }

    private productHasSerial(product: Product) {
        return (product.serialNumbers && product.serialNumbers.length) || !!product.serialNumber;
    }
}
