import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Order from '@/models/Order/Order';

@Component
export default class RentalOrderedDeliverd extends Vue {
    @Prop() public order?: Order;

    public get deviantOrderLines() {
        return this.order.deviantOrderLines;
    }

    public get deviantOrderBundles() {
        return this.order.deviantOrderBundles;
    }

    public get deviantOrderBundleProducts() {
        return this.order.deviantOrderBundleProducts;
    }
}
