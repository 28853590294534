import ReturnLine from './ReturnLine';
import Client from '../../modules/client/models/Client';
import ConstructionSite from '../ConstructionSite/ConstructionSite';
import ReturnStatusText, { ReturnStatus } from './ReturnStatus';
import moment from 'moment';
import ReturnBundle from './ReturnBundle';
import DamagedMissingProduct from './DamagedMissingProduct';
import Transporter from '../Transport/Transporter';
import ServiceLine from './ServiceLine';
import ClientContact from '@/modules/client/models/ClientContact';
import VirtualReturnLine from './VirtualReturnLine';
import ReturnBundleVm from './ReturnBundleVm';
import ReturnBundleProductVm from './ReturnBundleProductVm';
import ReturnBundleProduct from './ReturnBundleProduct';
import Product from '../../modules/inventory/models/Product';
import { ReturnType } from './ReturnType';
import PartLine from '@/models/Return/PartLine';

export default class Return {
    public returnId: number = 0;
    public clientId: number = 0;
    public client: Client = null;
    public constructionSiteId: number = 0;
    public constructionSite: ConstructionSite = null;
    public contactId: number = 0;
    public contact: ClientContact = null;
    public status: ReturnStatus = ReturnStatus.Unknown;
    public statusText?: string = '';
    public returnLines: ReturnLine[] = [];
    public returnBundles: ReturnBundle[] = [];
    public created: Date = null;
    public transportCosts: number = 0;
    public transportHours: number = 0;
    public signOffDate: Date = null;
    public returnDate: Date = null;
    public damagedOrMissingProducts: DamagedMissingProduct[] = [];
    public internalNote: string = '';
    public externalNote: string = '';
    public invoiceNote: string = '';
    public transporterId?: number = null;
    public transporter?: Transporter = new Transporter();
    public transportByCustomer: boolean = false;
    public services: ServiceLine[] = [];
    public usedParts: PartLine[] = [];
    public transportDescription: string = '';
    public deleted: boolean = false;
    public arrived: boolean = false;
    public returnType: ReturnType = ReturnType.Return;
    public packingSlipMemo: string = '';
    public waitingTime: number = 0;

    constructor(obj: any = {}) {
        Object.assign(this, obj);
        this.signOffDate = this.signOffDate ? moment(this.signOffDate).toDate() : null;
        this.returnDate = this.returnDate ? moment(this.returnDate).toDate() : null;
        if (this.status === ReturnStatus.Unknown) {
            this.status = ReturnStatus.Saved;
        }
    }

    public getStatusText() {
        if (this.deleted) {
            return 'Verwijderd';
        }

        return ReturnStatusText.getStatus(this.status);
    }

    public isSaved() {
        return this.status === ReturnStatus.Saved;
    }

    public isConcept() {
        return this.status === ReturnStatus.Concept;
    }

    public isBooked() {
        return this.status === ReturnStatus.Booked;
    }

    public get totalTransportCosts() {
        return this.transportCosts * this.transportHours;
    }

    public get nextSortOrder() {
        const first = [...this.returnedLinesAndBundles].sortBy('sortOrder', true).shift();
        return first ? first.sortOrder + 1 : 1;
    }

    public get returnLinesWithSerial() {
        return this.returnLines.filter((line: ReturnLine) => {
            return line.product && line.serialNumber;
        });
    }

    public get hasProductsWithSerial() {
        return this.returnLinesWithSerial.length > 0;
    }

    public get returnedLinesAndBundles() {
        const lines: VirtualReturnLine[] = [];
        this.returnLines.forEach((returnLine: ReturnLine) => {
            lines.push(new VirtualReturnLine(returnLine, null));
        });
        this.groupedReturnBundles.forEach((returnBundle: ReturnBundleVm) => {
            returnBundle.products.sortBy('sortOrder', false);
            lines.push(new VirtualReturnLine(null, returnBundle));
        });
        return lines.sortBy('sortOrder', false);
    }

    public get groupedReturnBundles(): ReturnBundleVm[] {
        const bundles: ReturnBundleVm[] = [];
        this.returnBundles.forEach((returnBundle: ReturnBundle) => {
            const productBundleId: number = returnBundle.productBundleId;
            let returnBundleVm = bundles.find((b) => b.productBundleId === productBundleId);
            if (!returnBundleVm) {
                returnBundleVm = new ReturnBundleVm({
                    bundleName: returnBundle.bundleName,
                    productBundleId,
                    amount: 0,
                    products: [],
                });
                bundles.push(returnBundleVm);
            }

            returnBundleVm.sortOrder = returnBundle.sortOrder;
            returnBundleVm.amount += returnBundle.amount;
            returnBundle.products.forEach((returnBundleProduct: ReturnBundleProduct) => {
                let bundleProductVm = returnBundleVm.products.find((p) => p.productId === returnBundleProduct.productId);
                if (!bundleProductVm) {
                    bundleProductVm = new ReturnBundleProductVm({
                        productId: returnBundleProduct.productId,
                        productName: returnBundleProduct.productName,
                        product: new Product({
                            productId: returnBundleProduct.productId,
                            name: returnBundleProduct.productName,
                        }),
                        productAmount: 0,
                        remaining: 0,
                        sortOrder: returnBundleProduct.sortOrder,
                        returnedAmount: returnBundleProduct.returnedAmount,
                    });
                    returnBundleVm.products.push(bundleProductVm);
                }

                bundleProductVm.productAmount += returnBundleProduct.productAmount;
            });
        });

        return bundles;
    }
}
