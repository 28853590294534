import Client from '../../modules/client/models/Client';
import Address from '../Address';
import ClientContact from '@/modules/client/models/ClientContact';
import IConstructionSiteInvoice from './IConstructionSiteInvoice';

export default class ConstructionSite {
    public id: number = 0;
    public constructionSiteId: number = 0;
    public name: string = '';
    public estimatedEndDate: string | Date = new Date();
    public clientId: number = 0;
    public client: Client = new Client();
    public isActive: boolean = false;
    public address: Address = new Address();
    public internalMemo: string = '';
    public about: string = '';
    public canClose: boolean;
    public contacts: ClientContact[] = [];
    public lastInvoiceViewMonth: IConstructionSiteInvoice;

    constructor(object: any = {}) {
        Object.assign(this, object);
    }
}
