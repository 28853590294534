import Vue from 'vue';
import Component from 'vue-class-component';
import SignaturePad from 'signature_pad';

@Component
export default class SignatureComponent extends Vue {
    public $refs: {
        canvas: HTMLCanvasElement;
    };
    private pad: SignaturePad;

    public mounted() {
        this.resizeCanvas();
        this.initSignaturePad();

        window.addEventListener('resize', this.resizeCanvas);
    }

    public beforeDestroy() {
        window.removeEventListener('resize', this.resizeCanvas);
    }

    public generatePng() {
        return this.pad.toDataURL('image/png');
    }

    public clear() {
        this.pad.clear();
    }

    private resizeCanvas() {
        // When zoomed out to less than 100%, for some very strange reason,
        // some browsers report devicePixelRatio as less than 1
        // and only part of the canvas is cleared then.
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        const canvas = this.$refs.canvas;
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext('2d').scale(ratio, ratio);
    }

    private initSignaturePad() {
        this.pad = new SignaturePad(this.$refs.canvas, {
            backgroundColor: 'rgb(255, 255, 255)',
        });
    }
}
