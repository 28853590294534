import Vue from 'vue';
import { BaseService } from './baseService';
import Order from '@/models/Order/Order';
import Return from '@/models/Return/Return';
import ReturnLine from '@/models/Return/ReturnLine';
import IOrderFilter from '@/interfaces/IOrderFilter';
import UpdateOrderCommand from '@/models/UpdateOrderCommand';
import IReturnFilter from '@/interfaces/Return/IReturnFilter';

export default class RentalService extends BaseService {
    private endpoint: string = `${Vue.$env().serviceEndpoint}orders`;
    private returnEndpoint: string = `${Vue.$env().serviceEndpoint}returns`;

    public getOrders(filter: IOrderFilter = null) {
        if (filter) {
            return this.get(this.endpoint + this.objectToQueryString(filter));
        }
        return this.get(this.endpoint);
    }

    public getOrdersOfSite(siteId: number, onlyBookedOrders: boolean) {
        return this.get(`${this.endpoint}/?constructionSites=${siteId}&onlyBookedOrders=${onlyBookedOrders}`);
    }

    public getOrdersOfClients(clients: number[], onlyBookedOrders: boolean) {
        const query = [];
        clients.forEach((siteId: number) => {
            query.push('clientId=' + siteId);
        });

        return this.get(`${this.endpoint}/?${query.join('&')}&onlyBookedOrders=${onlyBookedOrders}`);
    }

    public getOrder(orderId: number) {
        return this.get(`${this.endpoint}/${orderId}`);
    }

    public updateOrder(orderId: number, order: Order) {
        order.deliveryDate = this.formatDate(order.deliveryDate);
        const updateCommand = new UpdateOrderCommand(order, order.isPartialDelivery);
        return this.put(`${this.endpoint}/${orderId}`, updateCommand);
    }

    public deleteOrder(orderId: number) {
        return this.delete(`${this.endpoint}/${orderId}`);
    }

    public revertOrder(orderId: number) {
        return this.post(`${this.endpoint}/${orderId}/revert`);
    }

    // Returns
    public getReturns(filter: IReturnFilter = {}) {
        if (filter) {
            return this.get(`${this.returnEndpoint}${this.objectToQueryString(filter)}`);
        }
        return this.get(`${this.returnEndpoint}`);
    }

    public getReturnsOfSite(siteId: number) {
        return this.get(`${this.returnEndpoint}/?constructionSites=${siteId}`);
    }

    public createReturn(banaan: Return) {
        this.convertReturnDates(banaan);
        banaan.returnLines.forEach((returnLine: ReturnLine) => {
            delete returnLine.product;
        });

        return this.post(`${this.returnEndpoint}`, banaan);
    }

    public getReturn(returnId: number) {
        return this.get(`${this.returnEndpoint}/${returnId}`);
    }

    public finishReturn(returnId: number) {
        return this.post(`${this.returnEndpoint}/${returnId}/finish`);
    }

    public updateReturn(banaan: Return) {
        this.convertReturnDates(banaan);
        return this.put(`${this.returnEndpoint}/${banaan.returnId}`, banaan);
    }

    public deleteReturn(returnId: number) {
        return this.delete(`${this.returnEndpoint}/${returnId}`);
    }

    public revertReturn(returnId: number) {
        return this.post(`${this.returnEndpoint}/${returnId}/revert`);
    }

    // Sales order
    public getSalesOrders() {
        return this.get(`${this.endpoint}?orderType=SalesOrder`);
    }

    private convertReturnDates(banaan: Return) {
        banaan.signOffDate = this.formatDate(banaan.signOffDate);
        banaan.returnDate = this.formatDate(banaan.returnDate);
    }
}
