import Vue from 'vue';
import { BaseService } from './baseService';
import Transporter from '@/models/Transport/Transporter';
import TransporterType from '@/models/Transport/TransporterType';

export default class TransportService extends BaseService {
    private endpoint: string = `${Vue.$env().serviceEndpoint}transporters`;
    private typesEndpoint: string = `${Vue.$env().serviceEndpoint}transporter-types`;

    public getTransporters() {
        return this.get(`${this.endpoint}`);
    }

    public saveTransporter(transporter: Transporter) {
        return this.post(`${this.endpoint}`, transporter);
    }

    public updateTransporter(transporter: Transporter) {
        return this.put(`${this.endpoint}/${transporter.transporterId}`, transporter);
    }

    public getTransportTypes() {
        return this.get(`${this.typesEndpoint}`);
    }

    public saveTransporterType(transporterType: TransporterType) {
        return this.post(`${this.typesEndpoint}`, transporterType);
    }

    public updateTransporterType(transporterType: TransporterType) {
        return this.put(`${this.typesEndpoint}/${transporterType.transporterTypeId}`, transporterType);
    }
}
