export enum ProductDayPriceSource {
    OriginalPrice = 'OriginalPrice',
    ClientDiscount = 'ClientDiscount',
    PreviousOrder = 'PreviousOrder',
}

export const ProductDayPriceSourceText = {
    [ProductDayPriceSource.ClientDiscount]: 'Klantprijs',
    [ProductDayPriceSource.OriginalPrice]: 'Catalogusprijs',
    [ProductDayPriceSource.PreviousOrder]: 'Laatste order',
};
