import BaseRepository from '@/repositories/BaseRepository';
import GridWrapperSearch from '@prd/shared-ui/dist/services/components/Grid/models/GridWrapperSearch';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class RepositoryGrid extends Vue {
    @Prop() public repository: BaseRepository<any>;
    @Prop() public columns: any[];
    @Prop() public search: GridWrapperSearch;
    @Prop({ type: Number, default: 50 }) public pageSize: number;
    @Prop() public mapper: (value: any, index: number, array: any[]) => any;
    @Prop() public defaultSort: any;
    @Prop() public filter: (value: any) => any;

    public get allColumns() {
        const all = [...this.columns];
        if (this.$listeners.delete) {
            all.push({ cell: this.renderDelete, sortable: false, width: '75px' });
        }

        return all;
    }

    public get filteredDataItems() {
        if (this.filter) {
            return this.repository.all.filter(this.filter);
        }
        return this.repository.all;
    }

    public get dataItems() {
        if (this.mapper) {
            return this.filteredDataItems.map(this.mapper);
        }
        return this.filteredDataItems;
    }

    public mounted() {
        this.refresh();
    }

    public refresh() {
        if (this.repository.fetchAll) {
            this.repository.fetchAll();
        }
    }

    public rowclick(event) {
        this.$emit('rowclick', event);
    }

    private renderDelete(h, _, row) {
        const props = {
            icon: 'trash',
            callback: () => {
                this.$emit('delete', row.dataItem);
            },
        };

        return h(Vue.component('grid-button'), { props });
    }
}
