import moment from 'moment';

export default class DateHelper {
    public static ISODate(date: Date): string {
        return date.toISOString().substring(0, 10);
    }

    public static formatDate(date: Date): Date {
        return !!date ? new Date(moment(date).format('YYYY-MM-DD')) : null;
    }

    public static formatDateString(date: Date): string {
        return !!date ? moment(date).format('YYYY-MM-DD') : null;
    }

    public static addDays(date: Date, days: number): Date {
        const ms = 1000 * 3600 * 24 * days;
        return new Date(date.getTime() + ms);
    }

    public static addHours(date: Date, hours: number): Date {
        const ms = 1000 * 3600 * hours;
        return new Date(date.getTime() + ms);
    }

    public static getWeekNr(date: Date) {
        const first = new Date(date.getFullYear(), 0, 1);
        const days = Math.floor((date.getTime() - first.getTime()) / (24 * 60 * 60 * 1000));
        return Math.ceil(( date.getDay() + 1 + days) / 7);
    }

    public static overlap(from: string, to: string, from2: string, to2: string): boolean {
        const fromDate = new Date(new Date(from).toISOString()).getTime();
        const toDate = new Date(new Date(to).toISOString()).getTime();
        const fromDate2 = new Date(new Date(from2).toISOString()).getTime();
        const toDate2 = new Date(new Date(to2).toISOString()).getTime();
        const fromOverlap = toDate2 >= fromDate && fromDate2 <= fromDate;
        const toOverlap = fromDate2 <= toDate && toDate2 >= toDate;

        return toDate >= fromDate && toDate2 >= fromDate2 && (fromOverlap || toOverlap);
    }

    public static timeString(timeInMinutes?: number): string {
        if (!timeInMinutes) {
            return '00:00';
        }

        const hours = Math.floor(timeInMinutes / 60);
        const minutes = Math.ceil(timeInMinutes - hours * 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }
}
