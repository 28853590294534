import Vue from 'vue';

export default {
    error: (text: string) => {
        Vue.notify({
            title: 'Oh snap! Er is iets misgegaan...',
            text,
            type: 'error',
            duration: -1,
        });
    },
    success: (text: string) => {
        Vue.notify({
            title: text,
            type: 'success',
        });
    },
    clear() {
        Vue.notify({ clean: true });
    },
    pending(title: string) {
        Vue.notify({
            title,
            type: 'loading',
            duration: -1,
        });
    }
};
