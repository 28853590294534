import OrderBundleProduct from './OrderBundleProduct';
import IBundle from '@/interfaces/IBundle';

export default class OrderBundle implements IBundle {
    public orderBundleId: number = 0;
    public productBundleId: number = 0;
    public bundlePrice: number = 0;
    public bundleName: string = '';
    public amount: number = 0;
    public orderedAmount: number = 0;
    public products: OrderBundleProduct[] = [];
    public sortOrder: number = 0;

    constructor(obj: any = {}) {
        const bundle = JSON.parse(JSON.stringify(obj));
        if (bundle.products) {
            bundle.products.sortBy('sortOrder', false);
        }
        Object.assign(this, bundle);
    }
}
