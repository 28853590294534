import { BaseService } from './baseService';
import { UserRole } from '../models/UserRole';
import Vue from 'vue';
import { AxiosPromise } from 'axios';
import User from '@/models/User';
import to from 'await-to-js';
import InviteUser from '@/models/InviteUser';

export default class UserService extends BaseService {
    private endpoint = `${Vue.$env().loginEndpoint}api/roles/`;
    private addDeleteEndpoint = `${this.endpoint}user/:personId/role/:role`;
    private apiEndpoint = `${Vue.$env().serviceEndpoint}`;

    public async getUsers() {
        const [err, respon] = await to(this.get(`${this.apiEndpoint}users`));
        if (err) {
            return [];
        }

        return respon.data;
    }

    public inviteUser(user: InviteUser) {
        return this.post(`${this.apiEndpoint}invites`, user);
    }

    public getInvite(key: string) {
        return this.get(`${this.apiEndpoint}/invites/:key`, [['key', key]]);
    }

    public claimInvite(key: string, passsword: string) {
        return this.post(`${this.apiEndpoint}/invites/:key`, { password: passsword }, [['key', key]]);
    }

    public getUsersInRole(role: UserRole): AxiosPromise<any[]> {
        return this.get(`${this.endpoint}:role/users`, [['role', role]]);
    }

    public makeAddDeleteData(personId: number, role: UserRole): ReadonlyArray<[string, any]> {
        return [
            ['personId', personId],
            ['role', role as string],
        ];
    }

    public setUserRole(personId: number, role: UserRole) {
        return this.post(this.addDeleteEndpoint, null, this.makeAddDeleteData(personId, role));
    }

    public deleteUserRole(personId: number, role: UserRole) {
        return this.delete(this.addDeleteEndpoint, this.makeAddDeleteData(personId, role));
    }

    public async deleteUser(personId: number) {
        const [err] = await to(this.delete(`${this.apiEndpoint}users/${personId}`));
        return !err;
    }
}
