import GridWrapperSearch from '@prd/shared-ui/dist/services/components/Grid/models/GridWrapperSearch';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Grid, GridToolbar, GridNoRecords } from '@progress/kendo-vue-grid';
// import { BSkeletonTable } from 'bootstrap-vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

export enum PagerType {
    ServerSide = 'serverSide',
    ClientSide = 'clientSide',
}

@Component({
    components: {
        Grid,
        GridToolbar,
        GridNoRecords,
        // BSkeletonTable,
    },
})
export default class GridWrapper extends Vue {
    @Prop({ type: Boolean }) public loading: boolean;
    @Prop({ type: Boolean }) public flat: boolean;
    @Prop({ type: Boolean, default: true }) public enableSort: boolean;
    @Prop({ type: String, default: PagerType.ClientSide }) public enablePager: PagerType.ClientSide;
    @Prop({ type: Boolean, default: true }) public enableResize: boolean;
    @Prop({ type: Boolean }) public enableReorder: boolean;
    @Prop({ type: Boolean }) public enableFilter: boolean;
    @Prop({ type: Array }) public defaultSort: any;
    @Prop() public defaultFilter: any;
    @Prop({ type: Number, default: 30 }) public minHeight: number;
    @Prop({ type: Number, default: null }) public height: number | null;
    @Prop({ type: Boolean, default: false }) public translate: boolean;
    @Prop({ type: Object, default: null }) public search: GridWrapperSearch;

    // https://www.telerik.com/kendo-vue-ui/components/grid/api/GridProps/
    @Prop({ type: Boolean }) public alternatePerGroup: boolean;
    @Prop() public cancel: CallableFunction;
    @Prop() public cellRender: CallableFunction;
    @Prop() public cellClick: CallableFunction;
    @Prop({ type: Boolean }) public columnMenu: boolean;
    @Prop({ type: Boolean }) public columnVirtualization: boolean;
    @Prop() public columnreorder: CallableFunction;
    @Prop() public columnresize: CallableFunction;
    @Prop({ type: Array }) public columns: any[];
    @Prop({ type: Array }) public dataItems: any[];
    @Prop() public datastatechange: CallableFunction;
    @Prop() public edit: CallableFunction;
    @Prop({ type: String }) public editField: string;
    @Prop({ type: String }) public expandField: string;
    @Prop() public expandChange: CallableFunction;
    @Prop() public filterCellRender: CallableFunction;
    @Prop({ type: Array }) public group: any[];
    @Prop({ type: Boolean }) public groupable: boolean;
    @Prop() public groupchange: CallableFunction;
    @Prop() public headerCellRender: CallableFunction;
    @Prop() public headerselectionchange: CallableFunction;
    @Prop() public itemchange: CallableFunction;
    @Prop() public remove: CallableFunction;
    @Prop({ type: Number, default: 34 }) public rowHeight: number;
    @Prop() public rowRender: CallableFunction;
    @Prop() public save: CallableFunction;
    @Prop() public scrollable: null;
    @Prop({ type: String }) public selectedField: string;
    @Prop() public selectionchange: CallableFunction;
    @Prop() public sortchange: CallableFunction;

    @Prop({ type: Number, default: 0 }) public skip: number;
    @Prop({ type: Number, default: 50 }) public take: number;
    @Prop({ type: Number, default: 0 }) public total: number;

    public key: number = 0;
    public sort: any[] = [];
    public filter: any = {
        logic: 'and',
        filters: [],
    };
    public $refs!: {
        grid: any;
    };

    public get _dataItems() {
        if (!this.dataItems) {
            return [];
        }
        let items = this.dataItems;
        if (this.search !== null && this.search.searchValue) {
            items = items.filter((item: any) => this.search.matched(item));
            if (items.length <= this.skip) {
                this.skip = 0;
            }
        }
        return orderBy(filterBy(items, this.filter), this.sort);
    }

    public get dataItemsView() {
        if (this.enablePager == PagerType.ClientSide) {
            return this._dataItems.slice(this.skip, this.take + this.skip);
        }
        return this._dataItems;
    }

    public get totalDataItems() {
        if (this.total) {
            return this.total;
        }

        return this._dataItems ? this._dataItems.length : 0;
    }

    public get sortable() {
        if (this.enableSort) {
            return {
                allowUnsort: true,
                mode: 'multiple',
            };
        }
        return false;
    }

    public get toolbar() {
        return !!this.$slots.default || !!this.$slots.toolbar;
    }

    public created() {
        if (this.translate) {
            this.columns.forEach((c) => {
                if (c.title && c.title.length) {
                    c.headerCell = this.translateHeader;
                }
            });
        }
    }

    public mounted() {
        if (this.defaultSort) {
            this.sort = this.defaultSort;
        }

        if (this.defaultFilter) {
            this.filter = this.defaultFilter;
        }

        this.$watch('dataItems', this.setHeight);
        this.$watch('loading', this.setHeight);
        this.$watch('height', this.setHeight);
        this.setHeight();
    }

    private setHeight() {
        if (this.height && this.$refs.grid && this.$refs.grid.$el) {
            this.$refs.grid.$el.style.height = this.height.toString() + 'px';
        }
    }

    public pageChangeHandler(event) {
        if (this.enablePager === PagerType.ClientSide) {
            this.skip = event.page.skip;
            this.take = event.page.take;
        } else {
            this.$emit('page-change', event);
        }

        this.$nextTick(this.setHeight);
    }

    public sortChangeHandler(e) {
        this.sort = e.sort;
    }

    public rowClicked(e) {
        this.$emit('rowclick', e);
    }

    public filterChanged(e) {
        this.filter = e.filter;
    }

    public columnReorder(options) {
        this.$emit('update:columns', options.columns);
    }

    private translateHeader(h, _, props) {
        const self = this;
        return h(Vue.component('prd-translate-text'), {
            props: {
                translateKey: props.title,
                translateGroup: 'GRID',
                column: true,
                inline: true,
                updated() {
                    self.key++;
                },
            },
        });
    }
}
