import { Component } from 'vue-property-decorator';
import BasePage from '@/modules/base/BasePage';

@Component
export default class BaseComponentWithValidations extends BasePage {
    public validations: any = null;

    public setValidations(validations: any = {}) {
        this.validations = validations;
    }

    public clearValidations() {
        this.validations = null;
    }

    public isValid(objectName: string, propertyName: string) {
        if (this.validations === null || !this.validations[objectName]) {
            return true;
        }

        return !(this.validations[objectName].$dirty && this.validations[objectName][propertyName].$invalid);
    }
}
