import LedgerAccount from '@/models/LedgerAccount';
import Product from './Product';
import { ProductDayPriceSource } from './ProductDayPriceSource';

export default class ProductBundle {
    public productBundleId: number = 0;
    public name: string = '';
    public pricePerDay: number = 0;
    public dayPriceSource: ProductDayPriceSource;
    public products: Product[] = [];
    public ledgerAccount: LedgerAccount = new LedgerAccount();

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
