import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Return from '@/models/Return/Return';
import Transporter from '@/models/Transport/Transporter';
import BasePage from '@/modules/base/BasePage';

@Component
export default class ReturnDamagedMissingComponent extends BasePage {
    @Prop({ default: () => new Return() }) public banaan: Return;
    @Prop({ default: 'Retour en transport' }) public title: string;
    @Prop({ default: true }) public showTransport: boolean;
    public isLoadingTransporters: boolean = false;
    public transporters: Transporter[] = [];

    public async mounted() {
        await this.loadTransporters();
    }

    public transporterSelected(transporter: Transporter) {
        this.banaan.transporterId = transporter.transporterId;
    }

    private async loadTransporters() {
        this.isLoadingTransporters = true;
        this.transporters = await this.getTransporters();
        if (this.banaan.transporterId) {
            this.banaan.transporter = this.transporters.find((t: Transporter) => {
                return t.transporterId === this.banaan.transporterId;
            });
        }
        this.isLoadingTransporters = false;
    }
}
