import Order from '@/models/Order/Order';
import Return from '@/models/Return/Return';
import OrderLine from '@/models/Order/OrderLine';
import OrderBundle from '@/models/Order/OrderBundle';
import ProductBundle from '@/modules/inventory/models/ProductBundle';
import OrderBundleProduct from '@/models/Order/OrderBundleProduct';
import Product from '@/modules/inventory/models/Product';
import ReturnLine from '@/models/Return/ReturnLine';
import ReturnBundle from '@/models/Return/ReturnBundle';
import ReturnBundleProduct from '@/models/Return/ReturnBundleProduct';
import ProductWithSerial from '@/modules/inventory/models/ProductWithSerial';
import Buyout from '@/models/Buyout/Buyout';

export default class ReturnHelper {
    private orders: Order[];
    private returns: Return[];
    private remaining: any = {
        productIds: [] as number[],
        bundleIds: [] as number[],
        products: [] as Product[],
        bundles: [] as ProductBundle[],
        productIdAmounts: {},
        productAmounts: {},
        bundleProductAmounts: {},
        bundleAmounts: {},
    };

    constructor(orders: Order[], returns: Return[], buyouts: Buyout[]) {
        buyouts.forEach((buyout: Buyout) => { returns.push(buyout.return); });
        this.orders = orders.filter((order: Order) => {
            return (new Order(order)).isBooked();
        });
        this.returns = returns.filter((banaan: Return) => {
            return !banaan.deleted && (new Return(banaan)).isBooked();
        });
    }

    public compareOrdersAndReturns() {
        this.countNumbersOfOrders();
        this.countNumbersOfReturns();
    }

    public get remainingBundles(): ProductBundle[] {
        return this.remaining.bundles.filter((bundle: ProductBundle) => {
            return this.remaining.bundleIds.indexOf(bundle.productBundleId) > -1;
        });
    }

    public get remainingProducts(): Product[] {
        return this.remaining.products.filter((product: Product) => {
            return this.remaining.productIds.indexOf(product.productId) > -1;
        });
    }

    public get remainingProductsWithSerial(): ProductWithSerial[] {
        const products = [];
        Object.keys(this.remaining.productAmounts).forEach((key: string) => {
            const [productId] = key.split('-');
            const serialIndex = key.indexOf('-');
            let product = this.remaining.products.find((p: Product) => {
                return p.productId === parseInt(productId);
            });
            product = new ProductWithSerial(product);
            product.serialNumber = key.substr(serialIndex + 1);
            products.push(product);
        });
        return products;
    }

    public get remainingObject() {
        return this.remaining;
    }

    public get remainingBundleAmounts() {
        return this.remaining.bundleAmounts;
    }

    public getRemainingProduct(productId: number) {
        if (this.remaining.productIdAmounts[productId]) {
            return this.remaining.productIdAmounts[productId];
        }
        return 0;
    }

    public getRemainingProductWithSerial(productId: number, serialNumber: string) {
        if (this.remaining.productAmounts[`${productId}-${serialNumber}`]) {
            return this.remaining.productAmounts[`${productId}-${serialNumber}`];
        }
        return 0;
    }

    public getRemainingBundleProductAmount(bundleId, productId) {
        if (this.remaining.bundleProduct[bundleId] && this.remaining.bundleProduct[bundleId][productId]) {
            return this.remaining.bundleProduct[bundleId][productId];
        }
        return 0;
    }

    public getRemainingBundleAmount(bundleId) {
        if (this.remaining.bundleAmounts[bundleId]) {
            return this.remaining.bundleAmounts[bundleId];
        }
        return 0;
    }

    private countNumbersOfOrders() {
        this.orders.forEach((order: Order) => {
            order.orderLines.forEach((orderLine: OrderLine) => {
                orderLine = new OrderLine(orderLine);
                const key = `${orderLine.productId}-${orderLine.serialNumber}`;
                if (this.remaining.productIds.indexOf(orderLine.productId) < 0) {
                    this.remaining.productIds.push(orderLine.productId);
                    this.remaining.products.push(orderLine.product);
                    this.remaining.productIdAmounts[orderLine.productId] = 0;
                }

                if (!this.remaining.productAmounts[key]) {
                    this.remaining.productAmounts[key] = 0;
                }
                this.remaining.productAmounts[key] += orderLine.productAmount;
                this.remaining.productIdAmounts[orderLine.productId] += orderLine.productAmount;
            });

            order.orderBundles.forEach((orderBundle: OrderBundle) => {
                const productBundleId = orderBundle.productBundleId;
                if (this.remaining.bundleIds.indexOf(productBundleId) < 0) {
                    this.remaining.bundleIds.push(productBundleId);
                    const products = [];
                    orderBundle.products.forEach((orderBundleProduct: OrderBundleProduct) => {
                        products.push(orderBundleProduct.product);
                    });
                    this.remaining.bundles.push(new ProductBundle({
                        name: orderBundle.bundleName,
                        products,
                        productBundleId: orderBundle.productBundleId,
                    }));
                    this.remaining.bundleProductAmounts[productBundleId] = { total: 0 };
                    orderBundle.products.forEach((product: OrderBundleProduct) => {
                        this.remaining.bundleProductAmounts[productBundleId][product.productId] = 0;
                    });
                }

                if (!this.remaining.bundleAmounts[productBundleId]) {
                    this.remaining.bundleAmounts[productBundleId] = 0;
                }
                this.remaining.bundleAmounts[productBundleId] += orderBundle.amount;

                orderBundle.products.forEach((bundleProduct: OrderBundleProduct) => {
                    this.remaining.bundleProductAmounts[productBundleId][bundleProduct
                    .productId] += bundleProduct.productAmount;
                    this.remaining.bundleProductAmounts[productBundleId].total += bundleProduct.productAmount;
                });
            });
        });
    }

    private countNumbersOfReturns() {
        this.returns.forEach((banaan: Return) => {
            banaan.returnLines.forEach((returnLine: ReturnLine) => {
                returnLine = new ReturnLine(returnLine);
                const key = `${returnLine.productId}-${returnLine.serialNumber}`;
                if (this.remaining.productAmounts[key]) {
                    this.remaining.productAmounts[key] -= returnLine.productAmount;
                    this.remaining.productIdAmounts[returnLine.productId] -= returnLine.productAmount;

                    if (this.remaining.productAmounts[key] < 1) {
                        delete this.remaining.productAmounts[key];
                    }

                    if (this.remaining.productIdAmounts[returnLine.productId] < 1) {
                        this.remaining.productIds.splice(this.remaining.productIds.indexOf(returnLine.productId), 1);
                        delete this.remaining.productIdAmounts[returnLine.productId];
                    }
                }
            });

            banaan.returnBundles.forEach((returnBundle: ReturnBundle) => {
                const productBundleId = returnBundle.productBundleId;
                returnBundle.products.forEach((returnProduct: ReturnBundleProduct) => {
                    if (this.remaining.bundleProductAmounts[productBundleId]) {
                        this.remaining.bundleProductAmounts[productBundleId][returnProduct.productId] -= returnProduct.productAmount;
                        this.remaining.bundleProductAmounts[productBundleId].total -= returnProduct.productAmount;

                        if (this.remaining.bundleProductAmounts[productBundleId].total < 1 && this.remaining.bundleAmounts[productBundleId] <= 0) {
                            this.remaining.bundleIds.splice(this.remaining.bundleIds.indexOf(returnBundle.productBundleId), 1);
                            delete this.remaining.bundleProductAmounts[productBundleId];
                        }
                    }
                });

                if (this.remaining.bundleAmounts[productBundleId]) {
                    this.remaining.bundleAmounts[productBundleId] -= returnBundle.amount;
                }
            });
        });
    }
}
