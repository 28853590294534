export enum OrderStatusEnum {
    Unknown = 'Unknown',
    Saved = 'Saved',
    Concept = 'Concept',
    Booked = 'Booked',
}

export default class OrderStatus {
    public static getStatus(status: OrderStatusEnum = OrderStatusEnum.Unknown): string {
        if (this.status[OrderStatusEnum[status]]) {
            return this.status[OrderStatusEnum[status]];
        }

        return this.status[0];
    }

    public static getStatussen() {
        return [
            OrderStatus.status.Unknown,
            OrderStatus.status.Saved,
            OrderStatus.status.Concept,
            OrderStatus.status.Booked,
        ];
    }

    private static status = {
        Unknown: 'Onbekend',
        Saved: 'Nieuw',
        Concept: 'Concept',
        Booked: 'Geboekt',
    };
}
