import { UserRole } from '@/models/UserRole';
import BaseView from '@/modules/base/Base.vue';

export default {
    path: '/klant-beheer',
    name: 'client-management',
    component: BaseView,
    meta: { page: 'Klant beheer', roles: [UserRole.ClientManagement] },
    redirect: { name: 'clients' },
    children: [
        {
            path: 'klanten',
            name: 'clients',
            component: () => import(/* webpackChunkName: "client-overview" */ './views/ClientsOverview.vue'),
            meta: { title: 'UVA - Klanten', page: 'Overzicht', roles: [UserRole.ClientManagement] },
        },
        {
            path: 'klanten/:clientId',
            name: 'client',
            component: () => import(/* webpackChunkName: "client-edit" */ './views/ClientView.vue'),
            meta: { title: 'UVA - Klant', page: 'Detail', roles: [UserRole.ClientManagement], showBackButton: true },
        },
        {
            path: 'plaatsen',
            name: 'sites',
            component: () => import(/* webpackChunkName: "client-edit" */ '@/modules/client/views/SiteOverview.vue'),
            meta: { title: 'UVA - Werken', page: 'Overzicht', roles: [UserRole.ClientManagement] },
        },
        {
            path: 'plaatsen/:constructionSiteId',
            name: 'site',
            component: () => import(/* webpackChunkName: "client-edit" */ '@/modules/client/views/SiteView.vue'),
            meta: { title: 'UVA - Werk', scope: 'Klant beheer', page: 'Detail', roles: [UserRole.ClientManagement], showBackButton: true },
        },
    ],
};
