interface Array<T> {
    sortBy(prop: string, desc: boolean): T[];
}

Array.prototype.sortBy = function(prop: string, desc: boolean = true) {
    const locator = require('./locator').default;
    const lower = desc ? -1 : 1;
    const higher = desc ? 1 : -1;

    return this.sort((a: any, b: any) => {
        const valueA = locator(a, prop);
        const valueB = locator(b, prop);

        if (typeof(valueA) === 'string' && typeof(valueB) === 'string') {
            return valueA.toLowerCase() < valueB.toLowerCase() ? higher : lower;
        }

        return valueA < valueB ? higher : lower;
    });
};
