import Product from '@/modules/inventory/models/Product';

export default class ReturnBundleProductVm {
    public orderBundleProductId: number = 0;
    public productId: number = 0;
    public productName: string = '';
    public productAmount: number = 0;
    public returnAmount: number = 0;
    public remaining: number = 0;
    public product: Product;
    public sortOrder: number = 0;
    public returnedAmount: number = 0;

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
