import { UserRole } from '@/models/UserRole';
import BaseView from '@/modules/base/Base.vue';

export default {
    path: '/logistiek-beheer',
    name: 'logistics-management',
    component: BaseView,
    meta: { page: 'Logistiek', roles: [UserRole.Logistics] },
    redirect: { name: 'transporters' },
    children: [
        {
            path: 'transporteurs',
            name: 'transporters',
            component: () => import(/* webpackChunkName: "settings" */ './views/TransportersOverview.vue'),
            meta: {
                title: 'UVA - Logisitic',
                scope: 'Transporteurs',
                page: 'Overzicht',
                roles: [UserRole.Logistics],
            },
        },
        {
            path: 'transporteurs/:id',
            name: 'transporter',
            component: () => import(/* webpackChunkName: "settings" */ './views/TransportersOverview.vue'),
            meta: {
                title: 'UVA - Logisitic',
                scope: 'Transporteurs',
                page: 'Overzicht',
                roles: [UserRole.Logistics],
            },
        },
        {
            path: 'transporteur-types',
            name: 'transporter-types',
            component: () => import(/* webpackChunkName: "settings" */ './views/TransporterTypeOverview.vue'),
            meta: {
                title: 'UVA - Logisitic',
                scope: 'Transporteurs',
                page: 'Types',
                roles: [UserRole.Logistics],
            },
        },
        {
            path: 'medewerkers',
            name: 'staff',
            component: () => import(/* webpackChunkName: "settings" */ './views/StaffOverview.vue'),
            meta: {
                title: 'UVA - Logisitic',
                scope: 'Medewerkers',
                page: 'Overzicht',
                roles: [UserRole.Logistics],
            },
        },
    ],
};
