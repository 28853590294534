import Product from '@/modules/inventory/models/Product';
import IBundle from '@/interfaces/IBundle';
import IBundleProduct from '@/interfaces/IBundleProduct';

export default class SquareMeterHelper {
    private devide: number = 4.6;

    public calculate(bundle: IBundle, ordered: boolean = false): number {
        let squareMeter = 0;
        bundle.products.forEach((bundleProduct: IBundleProduct) => {
            if (!bundleProduct.product) {
                throw new Error(`Product is empty for: ${bundleProduct.productId}`);
            }

            const factor = this.getSquareMeterFactor(bundleProduct.product);
            const amount = (ordered) ? bundleProduct.orderedAmount : bundleProduct.productAmount;
            squareMeter += amount ? factor * parseFloat(amount.toString().replace(',', '.')) : 0;
        });

        return Math.ceil(squareMeter / this.devide);
    }

    public getSquareMeterFactor(product: Product): number {
        if (product.length) {
            return product.length / 1000;
        } else {
            return 0;
        }
    }
}
