import Vue from 'vue';
import { BaseService } from './baseService';
import IBuyOutFilter from '@/interfaces/IBuyOutFilter';

export default class RentalService extends BaseService {
    private endpoint: string = `${Vue.$env().serviceEndpoint}buyout`;

    public getBuyOuts(filter: IBuyOutFilter = null) {
        if (filter) {
            return this.get(this.endpoint + this.objectToQueryString(filter));
        }
        return this.get(this.endpoint);
    }

    public sentProposal(buyoutId: number, contactId: number) {
        return this.post(`${this.endpoint}/${buyoutId}/send-proposal`, { contactId });
    }

    public getProposal(buyoutId: number, hash: string) {
        return this.get(`${this.endpoint}/${buyoutId}/proposal?hash=${encodeURIComponent(hash)}`);
    }
}
