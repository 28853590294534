import { loginService } from '@/main';
import Vue from 'vue';
import Component from 'vue-class-component';
import JwtData from '../models/JwtData';
import MobileButtonVue from './MobileButton.vue';

@Component({
    components: {
        MobileBtn: MobileButtonVue,
    },
})
export default class MobileHeader extends Vue {
    public userName: string = '';

    public mounted() {
        this.userName = this.getUserName();
    }

    public signOut() {
        loginService.clearToken();
        window.location.reload();
    }

    public update() {
        window.location.reload();
    }

    private getUserName() {
        const data = loginService.getJwtData() as JwtData;
        // tslint:disable-next-line
        const unique_name = data.unique_name.find((value: string) => value.indexOf('@') < 0);
        return `${unique_name} ${data.family_name}`;
    }
}
