import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import ProductBundle from '@/modules/inventory/models/ProductBundle';
import Product from '@/modules/inventory/models/Product';
import BaseComponentWithValidations from '../../../../components/BaseComponentWithValidations';
import LedgerAccount from '@/models/LedgerAccount';

@Component
export default class ProductBundleEdit extends BaseComponentWithValidations {
    @Prop() public productBundle: ProductBundle;
    @Prop() public editMode: boolean;
    public products: Product[] = [];
    public isLoadingProducts: boolean = true;
    public accounts: LedgerAccount[] = [];

    public async mounted() {
        this.products = await this.getProducts();
        this.accounts = await this.getLedgerAccounts();
        this.isLoadingProducts = false;
    }

    public getNotChosenProducts() {
        return this.products.filter((product: Product) => {
            const exists = this.productBundle.products.find((productInBundle: Product) => {
                return productInBundle.productId === product.productId;
            });

            return !exists;
        });
    }

    public productSelected(product: Product) {
        product.sortOrder = this.productBundle.products.length + 1;
        this.productBundle.products.push(product);
    }

    public removeProductFromBundle(index: number) {
        this.productBundle.products.splice(index, 1);
    }
}
