/* tslint:disable */
import { register } from 'register-service-worker';

register(`${process.env.BASE_URL}service-worker.js`, {
    registered() {
        document.dispatchEvent(new CustomEvent('pwa:registered'));
    },
    updatefound() {
        document.dispatchEvent(new CustomEvent('pwa:updatefound'));
    },
    updated() {
        document.dispatchEvent(new CustomEvent('pwa:updated'));
    },
    error(error) {
        console.error('Error during service worker registration:', error)
    }
});
