import BaseView from '@/modules/base/Base.vue';

export default {
    path: '/onderhoud',
    name: 'maintenance-management',
    component: BaseView,
    meta: { page: 'Onderhoud', roles: [] },
    redirect: { name: 'maintenance-objects' },
    children: [
        {
            path: 'objecten',
            name: 'maintenance-objects',
            component: () => import(/* webpackChunkName: "maintenance" */ '@/modules/maintenance/views/Overview.vue'),
            meta: {
                title: 'UVA - Onderhoud',
                scope: 'Onderhoud',
                page: 'Objecten',
                roles: [],
            },
        },
        {
            path: 'objecten/:productId/:serialNumber',
            name: 'maintenance-object',
            component: () => import(/* webpackChunkName: "maintenance" */ '@/modules/maintenance/views/View.vue'),
            meta: {
                title: 'UVA - Onderhoud',
                scope: 'Onderhoud',
                page: 'Object',
                roles: [],
            },
        },
    ],
};
