import TitleWithBadge from './TitleWithBadge.vue';
import PrdTableLoader from './TableLoader.vue';
import CrudCard from './Cards/CrudCard.vue';
import ModalButtons from './Modals/ModalButtons.vue';
import CancelBtn from './CancelBtn.vue';
import ProductLink from './ProductLink.vue';
import PrdSignature from './Signature.vue';
import Status from './Status.vue';

export default {
    TitleWithBadge,
    PrdTableLoader,
    CrudCard,
    ModalButtons,
    CancelBtn,
    ProductLink,
    PrdSignature,
    Status,
};
