import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Return from '@/models/Return/Return';
import BasePage from '@/modules/base/BasePage';
import Product from '@/modules/inventory/models/Product';
import ServiceLine from '@/models/Return/ServiceLine';

@Component
export default class ReturnServicesComponent extends BasePage {
    @Prop({ type: Object, default: new Return() }) public banaan?: Return;
    @Prop({ type: Boolean, default: false }) public editable: boolean;
    @Prop({ type: Boolean, default: false }) public loading: boolean;
    public amountToAdd: number = 0;
    public services: Product[] = [];

    public async mounted() {
        this.services = await this.getServices();
    }

    public addService(service: Product) {
        const serviceLine = new ServiceLine({
            product: service,
            productId: service.productId,
            productPrice: service.dayPrice,
            productAmount: this.amountToAdd,
        });

        this.amountToAdd = 0;
        this.banaan.services.push(serviceLine);
    }

    public removeServiceLine(index) {
        this.banaan.services.splice(index, 1);
    }

    public get uniqueServices() {
        return this.services.filter((service: Product) => {
            const exists = this.banaan.services.find((line: ServiceLine) => {
                return line.productId === service.productId;
            });
            return !exists;
        });
    }
}
