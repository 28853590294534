import Product from '@/modules/inventory/models/Product';
import IBundleProduct from '@/interfaces/IBundleProduct';

export default class ReturnBundleProduct implements IBundleProduct {
    public productId: number = 0;
    public productAmount: number = 0;
    public productName: string = '';
    public product: Product = new Product();
    public sortOrder: number = 0;
    public returnedAmount: number = 0;

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
