import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridRouterLinkComponent extends Vue {
    @Prop() public text: string;
    @Prop() public url: string;
    @Prop() public isCombined: boolean;

    public goToUrl(e) {
        e.preventDefault();
        this.$router.push(this.url);
    }
}
