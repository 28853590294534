import IBundleProduct from '@/interfaces/IBundleProduct';
import Product from '@/modules/inventory/models/Product';

export default class OrderBundleProduct implements IBundleProduct {
    public orderBundleProductId: number = 0;
    public productId: number = 0;
    public productAmount: number = 0;
    public orderedAmount: number = 0;
    public productName: string = '';
    public product: Product = null;
    public sortOrder: number = 0;

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
