export default class ClientContact {
    public clientContactId: number = null;
    public firstName: string = '';
    public lastName: string = '';
    public phoneNumber: string = '';
    public emailAddress: string = '';
    public deleted: boolean = false;

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }

    public name(): string {
        return `${this.firstName} ${this.lastName}`;
    }
}
