import Vue from 'vue';
import { BaseService } from './baseService';
import LedgerAccount from '@/models/LedgerAccount';

export default class LedgerAccountService extends BaseService {
    private endpoint: string = `${Vue.$env().serviceEndpoint}ledger-accounts`;

    public getAccounts() {
        return this.get(`${this.endpoint}`);
    }

    public saveAccount(account: LedgerAccount) {
        return this.post(`${this.endpoint}`, account);
    }

    public updateAccount(account: LedgerAccount) {
        return this.put(`${this.endpoint}/${account.ledgerAccountId}`, account);
    }
}
