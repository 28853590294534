import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Order from '@/models/Order/Order';
import OrderLine from '@/models/Order/OrderLine';
import OrderBundle from '@/models/Order/OrderBundle';
import OrderBundleProduct from '@/models/Order/OrderBundleProduct';

@Component
export default class RentalViewComponent extends Vue {
    @Prop() public order: Order;
    @Prop() public totalOrder: Order;

    public pricePerDay(): number {
        let total = 0;
        this.order.orderLines.forEach((orderLine: OrderLine) => {
            if (orderLine.product) {
                total += orderLine.productPrice * orderLine.productAmount;
            }
        });

        this.order.orderBundles.forEach((orderBundle: OrderBundle) => {
            total += orderBundle.amount * orderBundle.bundlePrice;
        });
        total = Math.round(total * 100) / 100;
        return total;
    }

    public pricePerWeek(): number {
        return Math.round(this.pricePerDay() * 7 * 100) / 100;
    }

    public get parentLine() {
        return (orderLine: OrderLine) => {
            return this.totalOrder.orderLines.find((line: OrderLine) => line.productId === orderLine.productId);
        };
    }

    public get parentBundle() {
        return (orderBundle: OrderBundle) => {
            return this.totalOrder.orderBundles.find((bundle: OrderBundle) => bundle.productBundleId === orderBundle.productBundleId);
        };
    }

    public get parentProductBundle() {
        return (orderBundle: OrderBundle, product: OrderBundleProduct) => {
            const bundle = this.parentBundle(orderBundle);
            return bundle.products.find((p: OrderBundleProduct) => p.productId === product.productId);
        };
    }
}
