export enum UserRole {
    Staff = 'Staff',
    SalesUser = 'SalesUser',
    RentalUser = 'RentalUser',
    Stock = 'Stock',
    ClientManagement = 'ClientManagement',
    Logistics = 'Logistics',
    SiteAdmin = 'SiteAdmin',
    UniversalAdmin = 'UniversalAdmin',
    Member = 'Member',
    SuperAdmin = 'SuperAdmin',
}
