import { UserRole } from '@/models/UserRole';
import BaseView from '@/modules/base/Base.vue';

export default {
    path: '/instellingen-beheer',
    name: 'settings-management',
    component: BaseView,
    meta: { page: 'Instellingen', roles: [UserRole.SiteAdmin] },
    redirect: { name: 'roles' },
    children: [
        {
            path: 'email-templates',
            name: 'email-templates',
            component: () => import(/* webpackChunkName: "settings" */ './views/email-templates/Overview.vue'),
            meta: {
                title: 'UVA - Instellingen',
                scope: 'Instellingen',
                page: 'E-mail templates',
                roles: [UserRole.SiteAdmin],
            },
        },
        {
            path: 'rollen',
            name: 'roles',
            component: () => import(/* webpackChunkName: "settings" */ './views/Roles/Overview.vue'),
            meta: {
                title: 'UVA - Instellingen',
                scope: 'Instellingen',
                page: 'Rollen',
                roles: [UserRole.SiteAdmin],
            },
        },
        {
            path: 'rollen/uitnodigen',
            name: 'invite-member',
            component: () => import(/* webpackChunkName: "settings" */ './views/Roles/Invite.vue'),
            meta: {
                title: 'UVA - Instellingen',
                scope: 'Instellingen',
                page: 'Nodig gebruiker uit',
                roles: [UserRole.SiteAdmin],
            },
        },
        {
            path: 'uitnodigingen',
            name: 'invites',
            component: () => import(/* webpackChunkName: "settings" */ './views/invites/Overview.vue'),
            meta: {
                title: 'UVA - Instellingen',
                scope: 'Instellingen',
                page: 'Uitnodigingen',
                roles: [UserRole.SiteAdmin],
            },
        },
        {
            path: 'mijn-portaal-gebruikers',
            name: 'portal-users',
            component: () => import(/* webpackChunkName: "settings" */ './views/portal/PortalUserOverview.vue'),
            meta: {
                title: 'UVA - Instellingen',
                scope: 'Instellingen',
                page: 'Mijn portaal - gebruikers',
                roles: [UserRole.SiteAdmin],
            },
        },
        {
            path: 'mijn-portaal-uitnodigingen',
            name: 'portal-invites',
            component: () => import(/* webpackChunkName: "settings" */ './views/portal/PortalInviteOverview.vue'),
            meta: {
                title: 'UVA - Instellingen',
                scope: 'Instellingen',
                page: 'Mijn portaal - uitnodigingen',
                roles: [UserRole.SiteAdmin],
            },
        }
    ],
};
