import { loginService } from '@/main';
import to from 'await-to-js';
import { AxiosInstance } from 'axios';
import RepositoryContext from './context/RepositoryContext';

export default class BaseRepository<model, singleModel = model, allModel = model> {
    public context: RepositoryContext = new RepositoryContext();
    public one: singleModel = {} as singleModel;
    public all: allModel[] = [];
    public isFetching = false;
    public fetchedAll = false;
    public error = false;

    protected url: string = '';
    protected primaryKey: string = '';

    constructor(context: RepositoryContext = null) {
        if (context) {
            Object.assign(this.context, context);
        }
    }

    public setContext(context: RepositoryContext) {
        this.context = context;
    }

    public get service(): AxiosInstance {
        return loginService.authAxiosInstance as AxiosInstance;
    }

    public getQueryParams(): string {
        const args = [];
        if (this.context.args) {
            Object.keys(this.context.args).forEach((key: string) => {
                const value = this.context.args[key] ? this.context.args[key] : null;
                const encoded = encodeURIComponent(value);
                if (Array.isArray(value)) {
                    value.forEach((item) => {
                        args.push(`${key}=${item}`);
                    });
                } else if (!!value) {
                    args.push(`${key}=${encoded}`);
                }
            });
            return `?${args.join('&')}`;
        }
        return '';
    }

    public getAllUrl(): string {
        return this.url + this.getQueryParams();
    }

    public async fetchOne(): Promise<void> {
        if (!this.context.primaryValue) {
            throw Error('Fetch one is called, but not primary value is specified.');
        }

        this.error = false;
        this.isFetching = true;
        const [err, one] = await to(this.getOne(this.context.primaryValue));
        this.isFetching = false;

        if (err) {
            this.error = true;
        } else {
            this.one = one;
        }
    }

    public async fetchAll(): Promise<void> {
        this.error = false;
        this.isFetching = true;
        const [err, all] = await to(this.getAll());
        this.isFetching = false;

        if (err) {
            this.error = true;
        } else {
            this.all = all;
            this.fetchedAll = true;
        }
    }

    public async getOne(primaryValue: number | string = this.context.primaryValue): Promise<singleModel> {
        const response = await this.service.get(`${this.url}/${primaryValue}${this.getQueryParams()}`);
        return response.data;
    }

    public async getAll(): Promise<allModel[]> {
        const response = await this.service.get(`${this.url}${this.getQueryParams()}`);
        return response.data.items ? response.data.items : response.data;
    }

    protected getPrimaryValue(obj: any): number | null {
        if (!this.primaryKey) {
            throw new Error('Primary key not defined');
        }

        return obj ? obj[this.primaryKey] : null;
    }
}
