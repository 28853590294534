import Buyout from '@/models/Buyout/Buyout';
import DamagedMissingProduct from '@/models/Return/DamagedMissingProduct';
import BasePage from '@/modules/base/BasePage';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class BuyoutView extends BasePage {
    @Prop() public buyout: Buyout;

    public get banaan() {
        return this.buyout.return;
    }

    public getPrice(productId: number) {
        const damaged = this.banaan.damagedOrMissingProducts.find((prd: DamagedMissingProduct) => {
            return prd.productId === productId;
        });
        return damaged ? damaged.priceMissing : 0;
    }

    public getAmount(productId: number) {
        const damaged = this.banaan.damagedOrMissingProducts.find((prd: DamagedMissingProduct) => {
            return prd.productId === productId;
        });
        return damaged ? damaged.amountMissing : 0;
    }
}
