import ReturnBundleVm from './ReturnBundleVm';
import ReturnLine from './ReturnLine';

export default class VirtualReturnLine {
    public returnLine?: ReturnLine;
    public returnBundle?: ReturnBundleVm;
    public sortOrder: number = 0;

    constructor(returnLine?: ReturnLine, returnBundle?: ReturnBundleVm) {
        this.returnLine = returnLine;
        this.returnBundle = returnBundle;
        this.sortOrder = returnLine ? returnLine.sortOrder : returnBundle.sortOrder;
    }

    public get isLine() {
        return !!this.returnLine;
    }

    public get isBundle() {
        return !!this.returnBundle;
    }
}
