import Vue from 'vue';
import { BaseService } from './baseService';
import ConstructionSite from '@/models/ConstructionSite/ConstructionSite';
import RentalService from './RentalService';

export default class SiteSerivce extends BaseService {
    private rentalService: RentalService = new RentalService();
    private get endpoint(): string {
        return `${Vue.$env().serviceEndpoint}construction-sites`;
    }

    public getSites(isActive) {
        return this.get(`${this.endpoint}?isActive=${isActive}`);
    }

    public getSite(constructionSiteId: number) {
        return this.get(`${this.endpoint}/${constructionSiteId}`);
    }

    public saveSite(site: ConstructionSite) {
        return this.post(`${this.endpoint}`, site);
    }

    public getProducts(siteId: number) {
        return this.get(`${this.endpoint}/${siteId}/inventory`);
    }

    public deleteSite(site: ConstructionSite): Promise<any> {
        return this.delete(`${this.endpoint}/${site.constructionSiteId}`);
    }
}
