export default function(object: any, field: string = ''): string {
    const splittedItems = field.split('.');
    let realDataItem = object;

    for (let i = 0; i < splittedItems.length - 1; i++) {
        if (!realDataItem[splittedItems[i]]) {
            return '';
        }

        realDataItem = realDataItem[splittedItems[i]];
    }

    return realDataItem[splittedItems[splittedItems.length - 1]];
}
