import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Return from '@/models/Return/Return';
import ReturnLine from '@/models/Return/ReturnLine';
import Product from '@/modules/inventory/models/Product';
import ReturnBundle from '@/models/Return/ReturnBundle';
import ReturnBundleProduct from '@/models/Return/ReturnBundleProduct';
import DamagedMissingProduct from '@/models/Return/DamagedMissingProduct';
import DamageReason from '@/models/Return/DamageReason';
import List from '@/prototypes/List';
import DamageReasonProduct from '@/models/Return/DamageReasonProduct';
import EditableComponent from '../standard/EditableComponent';
import DamageReasonService from '@/services/DamageReasonService';
import to from 'await-to-js';

@Component
export default class ReturnAmountComopnent extends EditableComponent {
    @Prop({ type: Object, default: new Return() }) public banaan: Return;
    public reasons: DamageReason[] = [];

    public async mounted() {
        this.reasons = await this.getDamageReasons();
    }

    public get products() {
        const products: Product[] = [];
        const existingProductIds: number[] = [];
        this.banaan.returnLines.forEach((returnLine: ReturnLine) => {
            if (existingProductIds.indexOf(returnLine.productId) < 0) {
                products.push(returnLine.product);
                existingProductIds.push(returnLine.productId);
            }
        });

        this.banaan.returnBundles.forEach((returnBundle: ReturnBundle) => {
            returnBundle.products.forEach((product: ReturnBundleProduct) => {
                if (existingProductIds.indexOf(product.productId) < 0) {
                    products.push(product.product);
                    existingProductIds.push(product.productId);
                }
            });
        });

        return products;
    }

    public getReasonsOfProduct(product: DamagedMissingProduct) {
        return this.reasons.filter((damageReason: DamageReason) => {
            const exists = damageReason.products.find((p: DamageReasonProduct) => {
                return p.productId === product.productId;
            });
            return !!exists;
        });
    }

    public getProductIdAmount(productId: number) {
        let amount = 0;
        this.banaan.returnLines.forEach((returnLine: ReturnLine) => {
            if (returnLine.productId === productId) {
                amount = amount + parseInt(returnLine.productAmount.toString());
            }
        });

        this.banaan.returnBundles.forEach((returnBundle: ReturnBundle) => {
            returnBundle.products.forEach((product: ReturnBundleProduct) => {
                if (product.productId === productId) {
                    amount = amount + parseInt(product.productAmount.toString());
                }
            });
        });

        return amount;
    }

    public getRest(productId) {
        const totalAmount = this.getProductIdAmount(productId);
        let totalMissing = 0;
        this.banaan.damagedOrMissingProducts.filter((d: DamagedMissingProduct) => {
            return d.productId === productId;
        }).forEach((damagedMissing: DamagedMissingProduct) => {
            totalMissing += parseInt(damagedMissing.amountMissing.toString()) + parseInt(damagedMissing.amountDamaged.toString()) + parseInt(damagedMissing.failureAmount.toString());
        });
        return totalAmount - (totalMissing);
    }

    public addProduct(product: Product) {
        this.banaan.damagedOrMissingProducts.push(new DamagedMissingProduct({
            product,
            productId: product.productId,
        }));
    }

    public removeProduct(index: number) {
        this.banaan.damagedOrMissingProducts.splice(index, 1);
    }

    public setDescription(damageReason: DamageReason, product: DamagedMissingProduct) {
        const damageProduct = damageReason.products.find((p: DamageReasonProduct) => {
            return p.productId === product.productId;
        });

        product.description = damageReason.name;
        product.priceDamaged = damageProduct.price;
    }

    public removeDescription(product: DamagedMissingProduct) {
        product.description = '';
        product.priceDamaged = 0;
    }

    public get totalAmountDamaged() {
        const list = new List(this.banaan.damagedOrMissingProducts);
        return list.sum((product: DamagedMissingProduct) => product.amountDamaged);
    }

    public get totalPriceDamaged() {
        const list = new List(this.banaan.damagedOrMissingProducts);
        return list.sum((product: DamagedMissingProduct) => product.priceDamaged);
    }

    public get totalAmountMissing() {
        const list = new List(this.banaan.damagedOrMissingProducts);
        return list.sum((product: DamagedMissingProduct) => product.amountMissing);
    }

    public get totalPriceMissing() {
        const list = new List(this.banaan.damagedOrMissingProducts);
        return list.sum((product: DamagedMissingProduct) => product.priceMissing);
    }

    public get totalPrice() {
        let total = 0;
        this.banaan.damagedOrMissingProducts.forEach((p: DamagedMissingProduct) => {
            total += (p.amountDamaged * p.priceDamaged) + (p.amountMissing * p.priceMissing);
        });
        return total;
    }

    private async getDamageReasons(): Promise<DamageReason[]> {
        const damageService = new DamageReasonService();
        const [_, response] = await to(damageService.getReasons());
        return response.data.items as DamageReason[];
    }
}
