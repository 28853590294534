import { UserRole } from '@/models/UserRole';
import BaseView from '@/modules/base/Base.vue';

export default [
    {
        path: '/planning',
        component: BaseView,
        name: 'planning-root',
        meta: { title: 'UVA - Verhuur ', page: 'Verhuur', roles: [UserRole.RentalUser] },
        redirect: { name: 'planning' },
        children: [
            {
                name: 'planning',
                path: '',
                component: () => import(/* webpackChunkName: "planning.index" */ './views/Index.vue'),
                meta: { title: 'UVA - Planning ', page: 'Planning', roles: [UserRole.RentalUser] },
            },
        ],
    },
];
