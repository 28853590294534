export enum ReturnStatus {
    Unknown = 'Unknown',
    Saved = 'Saved',
    Concept = 'Concept',
    Booked = 'Booked',
    Deleted = 'Deleted',
}

export default class ReturnStatusText {
    public static getStatus(status: ReturnStatus = ReturnStatus.Unknown) {
        return this.status[status];
    }

    public static getStatussen() {
        return [
            ReturnStatusText.status.Unknown,
            ReturnStatusText.status.Saved,
            ReturnStatusText.status.Concept,
            ReturnStatusText.status.Booked,
        ];
    }

    private static status = {
        Unknown: 'Onbekend',
        Saved: 'Opgeslagen',
        Concept: 'Concept',
        Booked: 'Geaccepteerd',
        Deleted: 'Verwijderd',
    };
}
