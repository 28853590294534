import Vue from 'vue';
import moment from 'moment';
import Order from './models/Order/Order';
import Return from './models/Return/Return';
import Buyout from './models/Buyout/Buyout';
import WorkOrder from './models/WorkOrder/WorkOrder';

class Filters {
    public static formatDate(value: Date) {
        if (value) {
            return moment(value).format('DD-MM-YYYY');
        }
        return '';
    }

    public static formatPrice(value: number) {
        return new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 10,
        }).format(+value);
    }

    public static formatPriceFixed(value: number) {
        if (Number.isNaN(value) || value === null || value === undefined) {
            value = 0;
        }

        return new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(+value);
    }

    public static formatBuyoutNumber(buyout: Buyout) {
        return `A${buyout.buyoutProposalId.toString().padStart(5, '0')}`;
    }

    public static formatPercentage(value: number) {
        return `${Math.round(value * 10) / 10}%`;
    }

    public static formatDateAsDay(value: Date) {
        if (value) {
            return moment(value).format('dddd').substring(0, 2);
        }
        return '';
    }

    public static formatConstructionNumber(value: number) {
        return `W${value.toString().padStart(5, '0')}`;
    }

    public static formatOrderNumber(order: Order) {
        if (!order || !order.orderId) {
            return '';
        }
        return (order.created ? new Date(order.created).getFullYear() : '') + '' + order.orderId.toString().padStart(5, '0');
    }

    public static formatReturnNumber(banaan: Return) {
        return new Date(banaan.created).getFullYear() + '' + banaan.returnId.toString().padStart(5, '0');
    }

    public static workOrderNr(workOrder: WorkOrder) {
        return `${workOrder.workOrderId.toString().padStart(5, '0')}`;
    }
}

const filters = {
    formatDate: Filters.formatDate,
    formatPrice: Filters.formatPrice,
    formatPriceFixed: Filters.formatPriceFixed,
    formatBuyoutNumber: Filters.formatBuyoutNumber,
    formatPercentage: Filters.formatPercentage,
    formatDateAsDay: Filters.formatDateAsDay,
    formatConstructionNumber: Filters.formatConstructionNumber,
    formatOrderNumber: Filters.formatOrderNumber,
    formatReturnNumber: Filters.formatReturnNumber,
    workOrderNr: Filters.workOrderNr,
};

Object.keys(filters).forEach((filterKey: string) => {
    Vue.filter(filterKey, filters[filterKey]);
});

export default Filters;
