import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Client from '@/modules/client/models/Client';

@Component
export default class PrintHeaderComponent extends Vue {
    @Prop() public title: string;
    @Prop() public subTitle: string;
    @Prop({ default: () => new Client() }) public client: Client;
    @Prop({ type: String }) public createdBy: string;
    @Prop({ type: String }) public createdAt: string;
}
