import { UserRole } from '@/models/UserRole';
import { loginService } from '@/main';

export default class AccountHelper {
    public static getRoles() {
        if (!loginService.isLoggedIn()) {
            return [];
        }

        const authData = loginService.getJwtData();
        return authData.role;
    }

    public static getHomeRouteOfUser() {
        const roles = this.getRoles();
        const redirects: { [key: string]: string } = {};
        redirects[UserRole.RentalUser] = 'rentals';
        redirects[UserRole.SalesUser] = 'sales-orders';
        redirects[UserRole.Member] = 'picking-slip-dashboard';

        for (const role in redirects) {
            if (roles.indexOf(role) >= 0) {
                return redirects[role];
            }
        }

        return 'not-found';
    }

    public static hasRole(role: UserRole) {
        const roles = this.getRoles();
        return roles.indexOf(role) > -1;
    }
}
