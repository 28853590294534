/* tslint:disable:array-type */
export default class List {
    private array: Array<any> = [];

    constructor(array: Array<any>) {
        this.array = array;
    }

    public sum(callback: CallableFunction) {
        let sum = 0;
        this.array.forEach((item) => {
            sum += callback(item);
        });
        return sum;
    }
}
