import { UserRole } from '@/models/UserRole';
import BaseView from '@/modules/base/Base.vue';

export default {
    path: '/service',
    name: 'service-management',
    component: BaseView,
    meta: { page: 'Service', roles: [UserRole.SalesUser] },
    redirect: { name: 'service-reports' },
    children: [
        {
            path: 'service-rapport',
            name: 'service-reports',
            component: () => import(/* webpackChunkName: "service" */ '@/modules/service/views/Overview.vue'),
            meta: { title: 'UVA - Servicerapport', scope: 'Servicerapport', page: 'Overzicht', roles: [UserRole.SalesUser] },
        },
        {
            path: 'service-rapport/nieuw',
            name: 'create-service-report',
            component: () => import(/* webpackChunkName: "service" */ '@/modules/service/views/Create.vue'),
            meta: { title: 'UVA - Servicerapport', scope: 'Servicerapport', page: 'Nieuw', roles: [UserRole.SalesUser] },
        },
        {
            path: 'service-rapport/:reportId',
            name: 'service-report',
            component: () => import(/* webpackChunkName: "service" */ '@/modules/service/views/Edit.vue'),
            meta: { title: 'UVA - Servicerapport', scope: 'Servicerapport', page: 'Detail', roles: [UserRole.SalesUser] },
        },
        {
            path: 'service-rapport/:reportId/complete',
            name: 'service-report-complete',
            component: () => import(/* webpackChunkName: "service" */ '@/modules/service/views/ServiceComplete.vue'),
            meta: { title: 'UVA - Servicerapport', scope: 'Servicerapport', page: 'Afronden', roles: [UserRole.SalesUser] },
        },
        {
            path: 'service-rapport/:reportId/print',
            name: 'service-report-print',
            component: () => import(/* webpackChunkName: "service" */ '@/modules/service/views/Print.vue'),
            meta: { title: 'UVA - Servicerapport', scope: 'Servicerapport', page: 'Print', roles: [UserRole.SalesUser] },
        },
    ],
};
