import Product from '@/modules/inventory/models/Product';

export default class ReturnLine {
    public returnId: number = 0;
    public returnLineId: number = 0;
    public returnLineOrderId: number = 0;
    public product: Product = null;
    public productId: number = 0;
    public productAmount: number = 0;
    public sortOrder: number = 0;
    public serialNumber: string = '';
    public returnedAmount: number = 0;

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
