var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prd-grid-wrapper universal",class:{ flat: _vm.flat, 'no-toolbar': !_vm.toolbar, 'show-scroll': _vm.height ? +_vm.height > 0 : false }},[_vm._t("default"),_vm._t("toolbar"),(_vm.loading)?_c('div',{staticClass:"p-2"},[_c('b-skeleton-table',{attrs:{"rows":3,"columns":_vm.columns.length,"hide-header":true,"table-props":{
        small: true,
        fixed: true,
        tableClass: 'mb-0'
    }}})],1):_c('grid',{key:("grid_" + _vm.key),ref:"grid",class:{
        'sortable': _vm.enableSort,
        'resizeable': _vm.enableResize,
        'clickable': !!_vm.$listeners.rowclick
    },attrs:{"columns":_vm.columns,"data-items":_vm.dataItemsView,"filter":_vm.filter,"filterable":_vm.enableFilter,"pageable":_vm.enablePager !== null ? true : false,"total":_vm.totalDataItems,"skip":_vm.skip,"take":_vm.take,"resizable":_vm.enableResize,"reorderable":_vm.enableReorder,"row-render":_vm.rowRender,"sort":_vm.sort,"sortable":_vm.sortable},on:{"columnreorder":_vm.columnReorder,"filterchange":_vm.filterChanged,"pagechange":_vm.pageChangeHandler,"rowclick":_vm.rowClicked,"sortchange":_vm.sortChangeHandler}},[_c('grid-no-records',[_vm._t("no-records")],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }