import OrderStatus, { OrderStatusEnum } from '@/models/Order/OrderStatus';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class StatusComponent extends Vue {
    @Prop() public status: OrderStatusEnum;

    public get text() {
        return OrderStatus.getStatus(this.status);
    }

    public get variant() {
        const map = {
            [OrderStatusEnum.Unknown]: 'dark',
            [OrderStatusEnum.Saved]: 'secondary',
            [OrderStatusEnum.Concept]: 'warning',
            [OrderStatusEnum.Booked]: 'success',
        };

        return map[this.status];
    }
}
