import { BaseService } from './baseService';
import { UserRole } from '../models/UserRole';
import Vue from 'vue';
import { AxiosPromise } from 'axios';
import to from 'await-to-js';

export default class UserRoleService extends BaseService {
    private endpoint = `${Vue.$env().loginEndpoint}api/roles/`;
    private addDeleteEndpoint = `${this.endpoint}user/:personId/role/:role`;
    private apiEndpoint = `${Vue.$env().serviceEndpoint}`;

    public async getPermissions() {
        const [err, respon] = await to(this.get(`${this.apiEndpoint}permissions`));
        if (err) {
            return [];
        }

        return respon.data;
    }

    public getUsersInRole(role: UserRole): AxiosPromise<any[]> {
        return this.get(`${this.endpoint}:role/users`, [['role', role]]);
    }

    public makeAddDeleteData(personId: number, role: UserRole): ReadonlyArray<[string, any]> {
        return [
            ['personId', personId],
            ['role', role as string],
        ];
    }

    public setUserRole(personId: number, role: UserRole) {
        return this.post(this.addDeleteEndpoint, null, this.makeAddDeleteData(personId, role));
    }

    public deleteUserRole(personId: number, role: UserRole) {
        return this.delete(this.addDeleteEndpoint, this.makeAddDeleteData(personId, role));
    }
}
