export enum SerialNumberStatus {
    WrittenOff = 'WrittenOff',
    Rental = 'Rental',
    Available = 'Available',
    InMaintenance = 'InMaintenance',
    ToVerify = 'ToVerify',
}

export const SerialNumberStatusText = (status: SerialNumberStatus) => {
    const options = {
        [SerialNumberStatus.Available]: 'Beschikbaar',
        [SerialNumberStatus.Rental]: 'In huur',
        [SerialNumberStatus.WrittenOff]: 'Afgeschreven',
        [SerialNumberStatus.InMaintenance]: 'In onderhoud',
        [SerialNumberStatus.ToVerify]: 'In acceptatie',
    };
    return options[status];
};

export default class SerialNumber {
    public serialNumber: string = '';
    public rentalStatus: SerialNumberStatus = SerialNumberStatus.Available;
    public inMaintenance: boolean = false;
}
