export default class DeliveryAddress {
    public id?: number = 0;
    public deliveryAddressId: number = 0;
    public name: string = '';
    public postalCode: string = '';
    public addressLine1: string = '';
    public addressLine2: string = '';
    public city: string = '';

    constructor(obj?: Partial<DeliveryAddress>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
