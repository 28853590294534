import ReturnBundleProduct from './ReturnBundleProduct';
import IBundle from '@/interfaces/IBundle';

export default class ReturnBundle implements IBundle {
    public amount: number = 0;
    public bundleName: string = '';
    public productBundleId: number = 0;
    public products: ReturnBundleProduct[] = [];
    public sortOrder: number = 0;

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
