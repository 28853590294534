import Product from '@/modules/inventory/models/Product';
import moment from 'moment';
import Vue from 'vue';
import ProductContext from '../models/ProductContext';
import CrudRepository from '../../../repositories/CrudRepository';
import ProductPrice from '../models/Product/ProductPrice';
import ProductRecentOrder from '../models/ProductRecentOrder';
import { ProductRecentOrderType } from '../models/ProductRecentOrderType';
import { ProductStore } from '../models/ProductStore';

export default class ProductRepository extends CrudRepository<Product> {
    public declare context: ProductContext;
    public priceList: ProductPrice[] = [];
    public recentOrders: ProductRecentOrder[] = [];

    protected url: string = `${Vue.$env().serviceEndpoint}products`;
    protected primaryKey: string = 'productId';

    constructor(context?: ProductContext) {
        super(context);
    }

    public async getSerialNumbers(date: Date) {
        const stringDate = moment(date).set('hour', 23).set('minute', 59).toISOString();
        const response = await this.service.get(
            `${this.url}/${this.context.primaryValue}/serial-numbers?conceptDate=${stringDate}&store=${this.context.args.store}`,
        );
        return response ? response.data : [];
    }

    public async saveSerialNumbers(serialNumbers: string[]) {
        return this.service.post(`${this.url}/${this.context.primaryValue}/serial-numbers`, {
            serialNumbers,
        });
    }

    public async deleteSerialNumbers(serialNumbers: string[]) {
        return this.service.delete(`${this.url}/${this.context.primaryValue}/serial-numbers`, { data: { serialNumbers } });
    }

    public async inventory(mutationDate: Date = new Date()) {
        if (!this.context.args.store) {
            throw new Error('Store is not defined, define store before fetching inventory');
        }

        const params = [];
        params.push(`store=${this.context.args.store}`);
        params.push(`mutationDate=${mutationDate.toISOString()}`);

        const query = params.length > 0 ? `?${params.join('&')}` : '';
        const response = await this.service.get(`${this.url}/inventory${query}`);
        return response ? response.data.items : [];
    }

    public async fetchPriceList() {
        const params = [];
        if (this.context.clientId) {
            params.push(`clientId=${this.context.clientId}`);
        }

        if (this.context.siteId) {
            params.push(`constructionSiteId=${this.context.siteId}`);
        }

        const query = params.length > 0 ? `?${params.join('&')}` : '';
        const response = await this.service.get(`${this.url}/price-list${query}`);
        this.priceList = response ? response.data.items : [];
    }

    public async getPriceRange() {
        const response = await this.service.get(`${this.url}/${this.context.primaryValue}/price-range`);
        return response.data;
    }

    public async fetchRecentOrders(orderType: ProductRecentOrderType, limit: number = 5) {
        const response = await this.service.get(`${this.url}/${this.context.primaryValue}/recent-orders?order_type=${orderType}&recent=${limit}`);
        this.recentOrders = response.data;
    }

    public async setInventoryCheckedDate(storeType: ProductStore) {
        await this.service.post(`${this.url}/${this.context.primaryValue}/inventory-checked?store=${storeType}`);
    }
}
