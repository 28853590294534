import Product from '../../modules/inventory/models/Product';

export default class ServiceLine {
    public serviceLineId: number = null;
    public productId: number = 0;
    public productAmount: number = 0;
    public productPrice: number = 0;
    public product: Product;

    public constructor(obj: any) {
        Object.assign(this, obj);
    }
}
