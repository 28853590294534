import { UserRole } from '@/models/UserRole';
import BaseView from '@/modules/base/Base.vue';

export default  {
    path: '/verkoop-beheer',
    name: 'sales-management',
    component: BaseView,
    meta: { page: 'Losse Verkoop', roles: [UserRole.SalesUser] },
    redirect: { name: 'sales-orders' },
    children: [
        {
            path: 'losse-verkoop',
            name: 'sales-orders',
            component: () => import(/* webpackChunkName: "sales" */ './views/SalesOverview.vue'),
            meta: { title: 'UVA - Losse verkoop', scope: 'Losse verkoop', page: 'Overzicht', roles: [UserRole.SalesUser] },
        },
        {
            path: 'losse-verkoop/aanmaken',
            name: 'create-sales-order',
            component: () => import(/* webpackChunkName: "sales" */ './views/SalesCreate.vue'),
            meta: {
                title: 'UVA - Losse verkoop',
                scope: 'Losse verkoop',
                page: 'Aanmaken',
                roles: [UserRole.SalesUser],
                showBackButton: true,
            },
        },
        {
            path: 'losse-verkoop/:orderId',
            name: 'edit-sales-order',
            component: () => import(/* webpackChunkName: "sales" */ './views/SalesEdit.vue'),
            meta: { title: 'UVA - Losse verkoop', scope: 'Losse verkoop', page: 'Detail', roles: [UserRole.SalesUser], showBackButton: true },
        },
        {
            path: 'losse-verkoop/:orderId/print',
            name: 'print-sales-order',
            alias: 'sales-order-print',
            component: () => import(/* webpackChunkName: "sales" */ './views/SalesPrint.vue'),
            meta: { title: 'UVA - Losse verkoop', scope: 'Losse verkoop', page: 'Print', roles: [UserRole.SalesUser], showBackButton: true },
        },
        {
            path: 'losse-verkoop/:orderId/print-raw',
            name: 'print-sales-order-raw',
            alias: 'sales-order-print',
            component: () => import(/* webpackChunkName: "sales" */ './views/SalesPrintRaw.vue'),
            meta: { title: 'UVA - Losse verkoop', scope: 'Losse verkoop', page: 'Print', roles: [UserRole.SalesUser], showBackButton: true },
        },
        {
            path: 'offertes',
            name: 'quotations',
            component: () => import(/* webpackChunkName: "quotations" */ './views/QuotationsOverview.vue'),
            meta: { title: 'UVA - Offertes', scope: 'Offertes', page: 'Overzicht', roles: [UserRole.SalesUser] },
        },
        {
            path: 'offertes/aanmaken',
            name: 'create-quotation',
            component: () => import(/* webpackChunkName: "quotations" */ './views/QuotationCreate.vue'),
            meta: { title: 'UVA - Offertes', scope: 'Offertes', page: 'Aanmaken', roles: [UserRole.SalesUser] },
        },
        {
            path: 'offertes/:quotationId',
            name: 'quotation',
            component: () => import(/* webpackChunkName: "quotations" */ './views/QuotationView.vue'),
            meta: { title: 'UVA - Offertes', scope: 'Offertes', page: 'Detail', roles: [UserRole.SalesUser] },
        },
        {
            path: 'offertes/:quotationId/print',
            name: 'print-quotation',
            component: () => import(/* webpackChunkName: "quotations" */ './views/QuotationPrint.vue'),
            meta: { title: 'UVA - Offertes', scope: 'Offertes', page: 'Print', roles: [UserRole.SalesUser] },
        },
        {
            name: 'order-confirmations',
            path: 'opdrachten',
            component: () => import(/* webpackChunkName: "order-confirmations" */ '@/modules/sales/views/OrderConfirmationOverview.vue'),
            meta: { title: 'UVA - Opdrachtbevestiging ', page: 'Overzicht', roles: [UserRole.SalesUser] },
        },
        {
            name: 'create-confirmation-order',
            path: 'opdrachten/nieuw',
            component: () => import(/* webpackChunkName: "order-confirmations" */ '@/modules/sales/views/OrderConfirmationCreate.vue'),
            meta: { title: 'UVA - Opdrachtbevestiging ', page: 'Nieuw', roles: [UserRole.SalesUser] },
        },
        {
            name: 'order-confirmation',
            path: 'opdrachten/:orderId',
            component: () => import(/* webpackChunkName: "order-confirmations" */ '@/modules/sales/views/OrderConfirmationView.vue'),
            meta: { title: 'UVA - Opdrachtbevestiging ', page: 'Bewerken', roles: [UserRole.SalesUser] },
        },
    ],
};
