import Buyout from '@/models/Buyout/Buyout';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class GridCheckbox extends Vue {
    @Prop() public item: any;
    @Prop() public callback: CallableFunction;
    public checked: boolean = false;
}
