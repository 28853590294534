import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Product from '../../models/Product';
import IProductSelectorItem from '../../models/Product/IProductSelectorItem';
import { ProductSelectorType } from '../../models/Product/ProductSelectorType';
import ProductBundle from '../../models/ProductBundle';
import ProductGroup from '../../models/ProductGroup';
import { ProductType } from '../../models/ProductType';
import ProductBundleRepository from '../../repo/ProductBundleRepository';
import ProductGroupRepository from '../../repo/ProductGroupRepository';
import ProductRepository from '../../repo/ProductRepository';
import { ProductCategory } from '@/modules/inventory/models/ProductCategory';
import { ProductStore } from '../../models/ProductStore';

@Component
export default class ProductSelectorComponent extends Vue {
    @Prop({ type: Array as PropType<Array<ProductRepository[]>>, default: () => [] }) public productRepos: ProductRepository[];
    @Prop() public productRepo: ProductRepository;
    @Prop() public serviceRepo: ProductRepository;
    @Prop() public productBundleRepo: ProductBundleRepository;
    @Prop() public productGroupRepo: ProductGroupRepository;
    @Prop({ type: String, default: 'Selecteer product' }) public placeholder: string;
    @Prop({ type: Boolean }) public showArticleCode: boolean;
    @Prop({ type: Boolean, default: true }) public resetAfter: boolean;
    @Prop({ type: Boolean, default: false }) public onlySales: boolean;
    public loading: boolean = false;

    public get options(): Array<IProductSelectorItem<Product | ProductBundle | ProductGroup>> {
        const items: IProductSelectorItem<Product | ProductBundle | ProductGroup>[] = [];

        this.productRepos.forEach((repo: ProductRepository) => {
            if (
                (this.onlySales &&
                    (repo.context.args?.product_category === ProductCategory.Sales || repo.context.args.store === ProductStore.Sales)) ||
                !this.onlySales
            ) {
                repo.all.forEach((product: Product) => {
                    const description = this.showArticleCode && product.articleCode ? `${product.name} | ${product.articleCode}` : product.name;
                    items.push({
                        description,
                        type: ProductSelectorType.Product,
                        value: product as Product,
                    });
                });
            }
        });

        if (this.productRepo) {
            this.productRepo.all.forEach((product: Product) => {
                const description = this.showArticleCode && product.articleCode ? `${product.name} | ${product.articleCode}` : product.name;
                items.push({
                    description: `${description}`,
                    type: ProductSelectorType.Product,
                    value: product as Product,
                });
            });
        }

        if (this.serviceRepo) {
            this.serviceRepo.all.forEach((service: Product) => {
                items.push({
                    description: service.name,
                    type: ProductSelectorType.Product,
                    value: service,
                });
            });
        }

        if (this.productBundleRepo) {
            this.productBundleRepo.all.forEach((productBundle: ProductBundle) => {
                items.push({
                    description: productBundle.name,
                    type: ProductSelectorType.ProductBundle,
                    value: productBundle,
                });
            });
        }

        if (this.productGroupRepo) {
            this.productGroupRepo.all.forEach((productGroup: ProductGroup) => {
                items.push({
                    description: productGroup.name,
                    type: ProductSelectorType.ProductGroup,
                    value: productGroup,
                });
            });
        }
        return items.sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1));
    }

    public mounted() {
        this.fetchOptions();
    }

    public optionSelected(option: any) {
        this.$emit('select', option);
    }

    public customLabel(item: IProductSelectorItem<Product | ProductBundle | ProductGroup>) {
        const typeIcons = {
            [ProductSelectorType.Product]: 'shopping-bag',
            [ProductSelectorType.ProductBundle]: 'server',
            [ProductSelectorType.ProductGroup]: 'package',
        };
        const productIcons = {
            [ProductType.Product]: 'shopping-bag',
            [ProductType.Part]: 'link',
            [ProductType.Service]: 'tool',
        };

        let icon = typeIcons[item.type];
        if (item.type === ProductSelectorType.Product) {
            const product = item.value as Product;
            icon = productIcons[product.productType];
        }

        return icon ? `<i class="icon-${icon} mr-2"></i> ${item.description}` : item.description;
    }

    private async fetchOptions() {
        this.loading = true;
        const repos = [...this.productRepos, this.productRepo, this.serviceRepo, this.productBundleRepo, this.productGroupRepo];
        for (let i = 0; i < repos.length; i++) {
            const repo = repos[i];
            if (repo && !repo.fetchedAll) {
                await repo.fetchAll();
            }
        }
        this.loading = false;
    }
}
