import Axios from 'axios';
import to from 'await-to-js';
import IAddressResponse from '@/interfaces/IAddressResponse';

export default class AddressHelper {
    public static async find(zip: string): Promise<IAddressResponse> {
        const [err, response] = await to(Axios.get(`https://addressautocomplete.infocaster.net/AddressAutoCompletionService.svc/address/${zip}`));
        if (err || !response) {
            return null;
        }

        return response.data as IAddressResponse;
    }
}
