import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Order from '@/models/Order/Order';
import OrderLine from '@/models/Order/OrderLine';
import OrderBundle from '@/models/Order/OrderBundle';
import Product from '@/modules/inventory/models/Product';
import ProductBundle from '@/modules/inventory/models/ProductBundle';
import SquareMeterHelper from '@/helpers/SquareMeterHelper';
import OrderBundleProduct from '@/models/Order/OrderBundleProduct';
import BasePage from '@/modules/base/BasePage';
import ProductRepository from '@/modules/inventory/repo/ProductRepository';
import IProductSelectorItem from '@/modules/inventory/models/Product/IProductSelectorItem';
import { ProductSelectorType } from '@/modules/inventory/models/Product/ProductSelectorType';
import SerialNumberSelector from '@/modules/inventory/components/Product/SerialnumberSelector.vue';
import ProductSelectorComponent from '@/modules/inventory/components/Product/ProductSelector.vue';
import ProductPrice from '@/modules/inventory/models/Product/ProductPrice';
import ProductBundleRepository from '@/modules/inventory/repo/ProductBundleRepository';
import SelectedSerialNumber from '@/modules/inventory/models/SelectedSerialNumber';

@Component({
    components: {
        SerialnumberSelector: SerialNumberSelector,
        ProductSelector: ProductSelectorComponent,
    },
})
export default class RentalProductsAndbundles extends BasePage {
    @Prop({ default: null }) public order: Order;
    @Prop() public totalOrder: Order;
    @Prop() public productRepo: ProductRepository;
    @Prop() public serviceRepo: ProductRepository;
    @Prop() public productBundleRepo: ProductBundleRepository;
    @Prop({ type: Boolean, default: false }) public loading: boolean;
    @Prop({ type: Boolean, default: false }) public hidePrice: boolean;
    @Prop({ type: Boolean, default: false }) public hideNewLine: boolean;
    @Prop({ type: Boolean, default: false }) public dirty: boolean;
    public isOrderLineProductInvalid: boolean = false;
    public availableSerialPerProduct: any = {};

    public lineToAdd: any = {
        price: null,
        amount: null,
        orderedAmount: null,
    };

    public get selectedSerialNumbers(): SelectedSerialNumber[] {
        const numbers: SelectedSerialNumber[] = [];
        this.order.orderLines.forEach((line: OrderLine) => {
            if (line.product.productId && line.serialNumber) {
                numbers.push({ productId: line.product.productId, serialNumber: line.serialNumber });
            }
        });
        return numbers;
    }

    public get parentLine() {
        return (orderLine: OrderLine) => {
            return this.totalOrder.orderLines.find((line: OrderLine) => line.productId === orderLine.productId);
        };
    }

    public get parentBundle() {
        return (orderBundle: OrderBundle) => {
            return this.totalOrder.orderBundles.find((bundle: OrderBundle) => bundle.productBundleId === orderBundle.productBundleId);
        };
    }

    public get parentProductBundle() {
        return (orderBundle: OrderBundle, product: OrderBundleProduct) => {
            const bundle = this.parentBundle(orderBundle);
            return bundle.products.find((p: OrderBundleProduct) => p.productId === product.productId);
        };
    }

    public getPricePerday(): number {
        let total = 0;
        this.order.orderLines.forEach((orderLine: OrderLine) => {
            total += orderLine.productAmount * orderLine.productPrice;
        });
        this.order.orderBundles.forEach((orderBundle: OrderBundle) => {
            total += orderBundle.bundlePrice * orderBundle.amount;
        });
        return total;
    }

    public getTotalWeekPrice(): number {
        return this.getPricePerday() * 7;
    }

    public removeOrderBundleFromOrder(orderBundle: OrderBundle) {
        const b = this.order.orderBundles.find((bundle: OrderBundle) => {
            return bundle.orderBundleId === orderBundle.orderBundleId;
        });
        const index = this.order.orderBundles.indexOf(b);
        this.order.orderBundles.splice(index, 1);
    }

    public removeOrderLineFromOrder(orderLine: OrderLine) {
        const l = this.order.orderLines.find((line: OrderLine) => {
            return line.productId === orderLine.productId;
        });
        const index = this.order.orderLines.indexOf(l);
        this.order.orderLines.splice(index, 1);
    }

    public calculateSquareMeterForBundle(vOrderBundle: OrderBundle) {
        const squareMeterHelper = new SquareMeterHelper();
        const bundle = this.order.orderBundles.find((b) => {
            if (vOrderBundle.orderBundleId) {
                return b.orderBundleId === vOrderBundle.orderBundleId;
            }
            return b.productBundleId === vOrderBundle.productBundleId;
        });
        bundle.products.forEach((bundleProduct: OrderBundleProduct) => {
            const product = this.productRepo.all.find((p: Product) => p.productId === bundleProduct.productId);
            if (product) {
                bundleProduct.product = product;
            }
        });
        bundle.amount = squareMeterHelper.calculate(bundle);
        bundle.orderedAmount = squareMeterHelper.calculate(bundle, true);
    }

    public isSquareMeterBundle(orderBundle: OrderBundle) {
        return orderBundle.bundleName.toLowerCase().indexOf('vierkante meter') > -1;
    }

    public orderLineOptionSelected(obj: IProductSelectorItem<Product | ProductBundle>) {
        this.lineToAdd.obj = obj;
        this.lineToAdd.orderedAmount = this.lineToAdd.orderedAmount > 0 ? this.lineToAdd.orderedAmount : 0;
        if (!this.lineToAdd.amount) {
            this.lineToAdd.amount = 1;
        }

        switch (obj.type) {
            case ProductSelectorType.ProductBundle:
                const bundle = new ProductBundle(obj.value);
                this.lineToAdd.price = bundle.pricePerDay;
                break;
            default:
                const product = new Product(obj.value);
                this.lineToAdd.price = product.dayPrice;
                break;
        }

        this.addLineToOrder(this.lineToAdd);
    }

    public async addLineToOrder(line: any) {
        if (!line.obj) {
            this.isOrderLineProductInvalid = true;
            return;
        }
        this.isOrderLineProductInvalid = false;
        const obj = line.obj as IProductSelectorItem<Product | ProductBundle>;

        switch (obj.type) {
            case ProductSelectorType.ProductBundle:
                const bundle = new ProductBundle(obj.value);
                const products = [];
                bundle.products.forEach((p: Product) => {
                    products.push(
                        new OrderBundleProduct({
                            productId: p.productId,
                            productName: p.name,
                            productAmount: null,
                            product: p,
                            sortOrder: p.sortOrder,
                            orderedAmount: line.orderedAmount,
                        }),
                    );
                });

                products.sortBy('sortOrder', false);

                this.order.orderBundles.push(
                    new OrderBundle({
                        productBundleId: bundle.productBundleId,
                        bundlePrice: line.price,
                        bundleName: bundle.name,
                        amount: null,
                        products,
                        sortOrder: this.order.nextSortOrder,
                        orderedAmount: line.orderedAmount,
                    }),
                );

                await this.$nextTick();
                if (this.$refs.orderLineBundle) {
                    const item = this.$refs.orderLineBundle[this.order.orderBundles.length - 1];
                    item.$el.focus();
                }
                break;
            default:
                const product = new Product(obj.value);
                const price = this.productRepo.priceList.find((p: ProductPrice) => {
                    return p.productId === product.productId;
                });
                this.order.orderLines.push(
                    new OrderLine({
                        product,
                        productId: product.productId,
                        productAmount: line.amount > 0 ? line.amount : null,
                        productPrice: price.dayPrice,
                        sortOrder: this.order.nextSortOrder,
                        orderedAmount: line.orderedAmount,
                    }),
                );
                break;
        }

        this.lineToAdd = {
            price: 0,
            amount: null,
        };
    }

    public getProductPriceSource(productId) {
        if (!this.productRepo || !this.productRepo.priceList) {
            return '';
        }

        const price = this.productRepo.priceList.find((p: ProductPrice) => {
            return p.productId === productId;
        });

        if (price && price.dayPriceSource) {
            return `Prijs is gebasseerd op ${price.dayPriceSource}.`;
        }
        return '';
    }

    public reCalculateBundle(orderBundle: OrderBundle) {
        if (this.isSquareMeterBundle(orderBundle)) {
            this.calculateSquareMeterForBundle(orderBundle);
        }
    }

    public async getAvailableSerials(productId: number) {
        return this.productService.getSerialNumbers(productId, new Date());
    }

    public getSerialOptions(productId) {
        if (this.availableSerialPerProduct[productId]) {
            return this.availableSerialPerProduct[productId];
        }
        return [];
    }

    public setOrderLineValue(orderLine: OrderLine, prop, value) {
        const line = this.order.orderLines.find((l: OrderLine) => {
            if (orderLine.orderLineId) {
                return orderLine.orderLineId === l.orderLineId;
            }
            return l.productId === orderLine.productId;
        });
        if (!line) {
            throw new Error('could not find orderLine with product id: ' + orderLine.productId);
        }

        line[prop] = value;
    }

    public serialNumberSelected(orderLine: OrderLine, value) {
        this.setOrderLineValue(orderLine, 'serialNumber', value);
        this.setOrderLineValue(orderLine, 'productAmount', 1);
        this.setOrderLineValue(orderLine, 'orderedAmount', 1);
    }

    public setOrderBundleValue(orderBundle: OrderBundle, prop, value) {
        const bundle = this.order.orderBundles.find((b: OrderBundle) => {
            return b.orderBundleId === orderBundle.orderBundleId;
        });
        if (!bundle) {
            throw new Error('could not find orderBundle with product id: ' + orderBundle.orderBundleId);
        }

        bundle[prop] = value;
    }

    public setOrderBundleProductValue(orderBundle: OrderBundle, orderBundleProduct: OrderBundleProduct, prop, value) {
        const bundle = this.order.orderBundles.find((b: OrderBundle) => {
            return b.orderBundleId === orderBundle.orderBundleId;
        });
        if (!bundle) {
            throw new Error('could not find orderBundle with product id: ' + orderBundle.orderBundleId);
        }
        const product = bundle.products.find((p: OrderBundleProduct) => {
            return p.product.productId === orderBundleProduct.product.productId;
        });
        if (!product) {
            throw new Error(`Could not find property: ${prop}, on orderBundleProduct`);
        }

        product[prop] = +value;
    }

    public hasSerialNumbers(productId: number) {
        if (!this.productRepo || !this.productRepo.all) {
            return false;
        }

        const product = this.productRepo.all.find((p: Product) => {
            return p.productId === productId;
        });
        return product ? product.serialNumbers.length : false;
    }
}
