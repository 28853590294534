import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ConstructionSite from '@/models/ConstructionSite/ConstructionSite';
import ClientContact from '@/modules/client/models/ClientContact';
import DeliveryAddress from '@/modules/client/models/Address';

@Component
export default class PrintWorkDescriptionComponent extends Vue {
    @Prop({ default: () => new ConstructionSite() }) public constructionSite: ConstructionSite;
    @Prop({ default: () => new DeliveryAddress() }) public deliveryAddress: DeliveryAddress;
    @Prop({ default: () => new ClientContact() }) public contact: ClientContact;
    @Prop() public deliveryDate: Date;
    @Prop({ default: () => null }) public signOffDate: Date;
    @Prop({ default: () => null }) public returnDate: Date;
    @Prop({ default: () => null }) public date: Date;
    @Prop({ type: Boolean }) public transportByCustomer: boolean;

    public get address() {
        const addressLines: string[] = [];
        if (this.deliveryAddress && this.deliveryAddress.deliveryAddressId) {
            addressLines.push(...[
                this.deliveryAddress.addressLine1,
                this.deliveryAddress.addressLine2,
                this.deliveryAddress.postalCode,
                this.deliveryAddress.city,
            ]);
        }

        if (this.constructionSite) {
            addressLines.push(...[
                this.constructionSite.address.addressLine1,
                this.constructionSite.address.addressLine2,
                this.constructionSite.address.postalCode,
                this.constructionSite.address.city,
            ]);
        }
        return addressLines.filter((a?: string) => !!a).join('<br/>');
    }
}
