import Vue from 'vue';
import { BaseService } from '../../../services/baseService';
import Product from '@/modules/inventory/models/Product';
import ProductBundle from '@/modules/inventory/models/ProductBundle';
import to from 'await-to-js';
import { ProductType } from '@/modules/inventory/models/ProductType';
import moment from 'moment';
import IInspectionDate from '@/modules/inventory/models/Product/IInspectionDate';
import IUpdateInventory from '@/modules/inventory/models/Product/IUpdateInventory';
import ISiteInProduct from '@/modules/inventory/models/Product/ISiteInProduct';
import IUpdateGroup from '@/interfaces/Group/UpdateGroup';
import { ProductStore } from '../models/ProductStore';

export default class ProductService extends BaseService {
    private endpoint = `${Vue.$env().serviceEndpoint}products`;
    private endpointGroups = `${Vue.$env().serviceEndpoint}product-groups`;
    private endpointBundles = `${Vue.$env().serviceEndpoint}product-bundles`;

    public async getProducts(
        includeLedgerAccount: boolean = false,
        clientId?: number,
        constructionSiteId?: number,
        type: ProductType = ProductType.Product,
        store: ProductStore = ProductStore.Rental,
        mutationDate?: Date): Promise<Product[]> {
        let [axxError, axiosResponse] = [null, null];
        let url = '';
        if (clientId) {
            url = `${this.endpoint}?clientId=${clientId}&constructionSiteId=${constructionSiteId ? constructionSiteId : ''}&product_type=${type}`;
        } else {
            url = `${this.endpoint}?product_type=${type}`;
        }

        if (store !== ProductStore.None) {
            url += `&store=${store}`;
        }

        if (mutationDate) {
            url += `&mutationDate=${this.formatDateString(mutationDate)}`;
        }

        [axxError, axiosResponse] = await to(this.get(url));
        return new Promise<Product[]>((resolve, reject) => {
            if (axxError) {
                reject(axxError);
            }

            if (!includeLedgerAccount) {
                axiosResponse.data.items.forEach((product: Product) => {
                    delete product.ledgerAccount;
                });
            }

            resolve(axiosResponse.data.items);
        });
    }

    public getProduct(productId: number, store: ProductStore) {
        return this.get(`${this.endpoint}/${productId}?store=${store}`);
    }

    public saveProduct(product: Product) {
        return this.post(this.endpoint, product);
    }

    public getProductGroups() {
        return this.get(this.endpointGroups);
    }

    public createProductGroup(group) {
        return this.post(this.endpointGroups, group);
    }

    public updateProductGroup(group: IUpdateGroup) {
        return this.put(`${this.endpointGroups}/${group.productGroupId}`, group);
    }

    public deleteProductGroup(groupId: number) {
        return this.delete(`${this.endpointGroups}/${groupId}`);
    }

    public updateInventory(productId: number, inventory: IUpdateInventory, store: ProductStore) {
        inventory.mutationDate = this.formatDate(inventory.mutationDate);
        return this.post(`${this.endpoint}/${productId}/inventory?store=${store}`, inventory);
    }

    public deleteProduct(product: Product) {
        return this.delete(`${this.endpoint}/${product.productId}`);
    }

    public async getLocations(productId: number): Promise<ISiteInProduct[]> {
        const [err, response] = await to(this.get(`${this.endpoint}/${productId}/construction-sites`));
        if (err) {
            return null;
        }
        return response.data as ISiteInProduct[];
    }

    public async getProductBundles(clientId?: number): Promise<ProductBundle[]> {

        let [error, response] = [null, null];
        if (clientId) {
            [error, response] = await to(this.get(`${this.endpointBundles}?clientId=${clientId}`));
        } else {
            [error, response] = await to(this.get(`${this.endpointBundles}`));
        }

        return new Promise<ProductBundle[]>((resolve, reject) => {
            if (error) {
                reject(error);
            }

            resolve(response.data.items);
        });
    }

    public getProductBundle(productBundleId: number) {
        return this.get(`${this.endpointBundles}/${productBundleId}`);
    }

    public saveProductBundle(productBundle: ProductBundle) {
        return this.post(`${this.endpointBundles}`, productBundle);
    }

    public async getSerialNumbers(productId: number, date: Date): Promise<string[]> {
        const stringDate = moment(date).set('hour', 23).set('minute', 59).toISOString();
        const [err, response] = await to(this.get(`${this.endpoint}/${productId}/serial-numbers?conceptDate=${stringDate}`));
        return !err ? response.data as string[] : null;
    }

    public saveSerialNumbers(productId: number, serialNumbers: string[]) {
        return this.post(`${this.endpoint}/${productId}/serial-numbers`, { serialNumbers });
    }

    public deleteSerialNumbers(productId: number, serialNumbers: string[]) {
        return this.delete(`${this.endpoint}/${productId}/serial-numbers`, [], { data: { serialNumbers } });
    }

    public async getInspectionDates(productId: number): Promise<IInspectionDate[]> {
        const [err, response] = await to(this.get(`${this.endpoint}/${productId}/inspection-dates`));
        return !err ? response.data as IInspectionDate[] : null;
    }

    public async addInspectionDate(productId: number, inspection: IInspectionDate) {
        inspection.nextInspectionDate = this.formatDateString(new Date(inspection.nextInspectionDate));
        const [err] = await to(this.post(`${this.endpoint}/${productId}/inspection-dates`, inspection));
        return !err;
    }

    public async deleteInspectionDate(productId: number, inspectionId: number) {
        const [err] = await to(this.delete(`${this.endpoint}/${productId}/inspection-dates/${inspectionId}`));
        return !err;
    }
}
