import Vue from 'vue';
import ClientService from '@/services/ClientService';
import Client from '@/modules/client/models/Client';
import ConstructionSite from '@/models/ConstructionSite/ConstructionSite';
import Product from '@/modules/inventory/models/Product';
import to from 'await-to-js';
import SiteSerivce from '@/services/SiteService';
import ProductService from '@/modules/inventory/services/ProductService';
import Order from '@/models/Order/Order';
import ProductOnSite from '@/modules/inventory/models/ProductOnSite';
import RentalService from '@/services/RentalService';
import RenderHelper from '@/helpers/RenderHelper';
import ProductBundle from '@/modules/inventory/models/ProductBundle';
import ProductGroup from '@/modules/inventory/models/ProductGroup';
import Return from '@/models/Return/Return';
import LedgerAccountService from '@/services/LedgerAccountService';
import LedgerAccount from '@/models/LedgerAccount';
import DamageReasonService from '@/services/DamageReasonService';
import DamageReason from '@/models/Return/DamageReason';
import TransporterType from '@/models/Transport/TransporterType';
import TransportService from '@/services/TransportService';
import Transporter from '@/models/Transport/Transporter';
import { ProductType } from '@/modules/inventory/models/ProductType';
import UserRoleService from '@/services/UserRoleService';
import UserService from '@/services/UserService';
import NotifyHelper from '@/helpers/NotifyHelper';
import { ProductStore } from '@/modules/inventory/models/ProductStore';
import BuyOutService from '@/services/BuyOutService';

export default class BasePage extends Vue {
    public isLoading: boolean = true;
    protected clientService: ClientService = new ClientService();
    protected siteService: SiteSerivce = new SiteSerivce();
    protected productService: ProductService = new ProductService();
    protected rentalService: RentalService = new RentalService();
    protected buyOutService: BuyOutService = new BuyOutService();
    protected ledgerAccountService: LedgerAccountService = new LedgerAccountService();
    protected damageReasonService: DamageReasonService = new DamageReasonService();
    protected renderHelper: RenderHelper = null;
    protected transportService: TransportService = new TransportService();
    protected userRoleService: UserRoleService = new UserRoleService();
    protected userService: UserService = new UserService();

    /** Notifications */
    public showValidationErrors(errors: string[]): void {
        const errorsHtml = errors
            .map((item) => {
                return '<li>' + item + '</li>';
            })
            .join('');

        // First clean all previous errors
        this.clearNotifications();
        this.$notify({
            title: 'Het opslaan is niet gelukt, verbeter alstublieft de volgende waarschuwingen:',
            text: '<ul>' + errorsHtml + '</ul>',
            type: 'error',
            duration: -1,
        });
    }

    public showFailedResponse(error: string, consoleError: any): void {
        this.showError(error);
    }

    public showPending(message: string): void {
        this.$notify({
            title: message,
            type: 'loading',
            duration: -1,
        });
    }

    public showError(error: string): void {
        NotifyHelper.error(error);
    }

    public showSuccess(message: string): void {
        NotifyHelper.success(message);
    }

    public clearNotifications(): void {
        this.$notify({
            clean: true,
        });
    }

    public clearAndShowSuccess(message: string): void {
        this.clearNotifications();
        this.showSuccess(message);
    }

    public clearAndShowError(error: string, consoleError: any = null): void {
        this.clearNotifications();
        this.showFailedResponse(error, consoleError);
    }

    protected async getClients(isDeleted: boolean = false): Promise<Client[]> {
        const [err, response] = await to(this.clientService.getClients(isDeleted));
        if (err || !response) {
            this.clearAndShowError('Mislukt om klanten op te halen.', err);
            return [] as Client[];
        }

        return response.data.items as Client[];
    }

    protected async getSites(isActive: boolean = true): Promise<ConstructionSite[]> {
        const [err, response] = await to(this.siteService.getSites(isActive));
        if (err || !response) {
            this.clearAndShowError('Mislukt om werken op te halen.', err);
            return [] as ConstructionSite[];
        }

        return response.data.items as ConstructionSite[];
    }

    protected async getProducts(
        includeLedgerAccount: boolean = false,
        clientId?: number,
        constructionSiteId?: number,
        mutationDate?: Date): Promise<Product[]> {
        const [err, response] = await to(this.productService.getProducts(
            includeLedgerAccount,
            clientId,
            constructionSiteId,
            ProductType.Product,
            ProductStore.Rental,
            mutationDate
        ));

        if (err || !response) {
            this.clearAndShowError('Mislukt om producten op te halen.', err);
            return [] as Product[];
        }

        return response;
    }

    protected async getServices(includeLedgerAccount: boolean = false, clientId?: number, constructionSiteId?: number): Promise<Product[]> {
        const [err, response] = await to(this.productService.getProducts(
            includeLedgerAccount,
            clientId,
            constructionSiteId,
            ProductType.Service,
            ProductStore.None,
        ));

        if (err || !response) {
            this.clearAndShowError('Mislukt om producten op te halen.', err);
            return [] as Product[];
        }

        return response;
    }

    protected async getParts(includeLedgerAccount: boolean = false, clientId?: number, constructionSiteId?: number): Promise<Product[]> {
        const [err, response] = await to(this.productService.getProducts(
            includeLedgerAccount,
            clientId,
            constructionSiteId,
            ProductType.Part,
            ProductStore.None,
        ));

        if (err || !response) {
            this.clearAndShowError('Mislukt om producten op te halen.', err);
            return [] as Product[];
        }

        return response;
    }

    protected async getSiteProducts(siteId: number): Promise<ProductOnSite[]> {
        const [err, response] = await to(this.siteService.getProducts(siteId));
        if (err || !response) {
            this.clearAndShowError('Mislukt om producten op te halen voor dit werk.', err);
            return [] as ProductOnSite[];
        }

        return response.data.items as ProductOnSite[];
    }

    protected async getSiteOrders(siteId: number, onlyBookedOrders: boolean = true) {
        const [err, response] = await to(this.rentalService.getOrdersOfSite(siteId, onlyBookedOrders));
        if (err || !response) {
            this.clearAndShowError('Mislukt om orders op te halen voor dit werk.');
            return [];
        }

        return response.data.items.map((o: any) => new Order(o));
    }

    protected async getSiteReturns(siteId: number): Promise<Return[]> {
        const [err, response] = await to(this.rentalService.getReturnsOfSite(siteId));
        if (err || !response) {
            this.clearAndShowError('Mislukt om returns op te halen voor dit werk.');
            return [];
        }

        return response.data.items as Return[];
    }

    protected async getOrdersOfClients(clients: number[], onlyBookedOrders: boolean = true) {
        const [err, response] = await to(this.rentalService.getOrdersOfClients(clients, onlyBookedOrders));
        if (err || !response) {
            this.clearAndShowError('Mislukt om orders op te halen voor dit werk.');
            return [];
        }

        return response.data.items as Order[];
    }

    protected async getProductBundles(clientId?: number): Promise<ProductBundle[]> {
        const [err, response] = await to(this.productService.getProductBundles(clientId));
        if (err || !response) {
            this.clearAndShowError('Mislukt om product bundels op te halen.');
            return [] as ProductBundle[];
        }

        return response;
    }

    protected async getProductGroups(): Promise<ProductGroup[]> {
        const [err, response] = await to(this.productService.getProductGroups());
        if (err || !response) {
            this.clearAndShowError('Mislukt om product groepen op te halen.');
            return [];
        }

        return response.data.items as ProductGroup[];
    }

    protected async getLedgerAccounts(): Promise<LedgerAccount[]> {
        const [err, response] = await to(this.ledgerAccountService.getAccounts());
        if (err || !response) {
            this.clearAndShowError('Mislukt om grootboekrekeningen op te halen.');
            return [];
        }

        return response.data.items as LedgerAccount[];
    }

    protected async getDamageReasons(): Promise<DamageReason[]> {
        const [err, response] = await to(this.damageReasonService.getReasons());
        if (err || !response) {
            this.clearAndShowError('Mislukt om grootboekrekeningen op te halen.');
            return [];
        }

        return response.data.items as DamageReason[];
    }

    protected async getTransporters() {
        const [err, response] = await to(this.transportService.getTransporters());
        if (err || !response) {
            this.clearAndShowError('Mislukt om transporteurs op te halen.');
            return [];
        }

        return response.data.items as Transporter[];
    }

    protected async getTransportersTypes(): Promise<TransporterType[]> {
        const [err, response] = await to(this.transportService.getTransportTypes());
        if (err || !response) {
            this.clearAndShowError('Mislukt om transporteur types op te halen.');
            return [];
        }

        return response.data.items as TransporterType[];
    }
}
