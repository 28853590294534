import { UserRole } from '@/models/UserRole';
import BaseView from '@/modules/base/Base.vue';
const roles = [UserRole.Logistics, UserRole.SalesUser, UserRole.SiteAdmin, UserRole.UniversalAdmin];

export default {
    path: '/inkoop-beheer',
    name: 'purchase-management',
    component: BaseView,
    meta: { page: 'Inkoop', roles },
    redirect: { name: 'suppliers' },
    children: [
        {
            path: 'inkoop-opdracht',
            name: 'purchase-orders',
            component: () => import(/* webpackChunkName: "purchase" */ '@/modules/purchase/views/purchase-order/PurchaseOrders.vue'),
            meta: {
                title: 'UVA - Inkoop',
                scope: 'Inkoop',
                page: 'Aanmaken',
                roles,
            },
        },
        {
            path: 'inkoop-opdracht/aanmaken',
            name: 'create-purchase-order',
            component: () => import(/* webpackChunkName: "purchase" */ '@/modules/purchase/views/purchase-order/CreateOrder.vue'),
            meta: {
                title: 'UVA - Inkoop',
                scope: 'Inkoop',
                page: 'Aanmaken',
                roles,
            },
        },
        {
            path: 'verkoop-naar-verhuur/aanmaken',
            name: 'sales-to-rental',
            component: () => import(/* webpackChunkName: "purchase" */ '@/modules/purchase/views/transfer/SalesToRental.vue'),
            meta: {
                title: 'UVA - Inkoop',
                scope: 'Inkoop',
                page: 'Aanmaken',
                roles,
            },
        },
        {
            path: 'inkoop-opdracht/:orderId',
            name: 'purchase-order',
            component: () => import(/* webpackChunkName: "purchase" */ '@/modules/purchase/views/purchase-order/EditOrder.vue'),
            meta: {
                title: 'UVA - Inkoop',
                scope: 'Inkoop',
                page: 'Opdracht',
                roles,
            },
        },
        {
            path: 'leveranciers',
            name: 'suppliers',
            component: () => import(/* webpackChunkName: "purchase" */ '@/modules/purchase/views/supplier/Suppliers.vue'),
            meta: {
                title: 'UVA - Inkoop',
                scope: 'Leveranciers',
                page: 'Detail',
                roles,
            },
        },
        {
            path: 'leveranciers/:supplierId',
            name: 'supplier',
            component: () => import(/* webpackChunkName: "purchase" */ '@/modules/purchase/views/supplier/EditSupplier.vue'),
            meta: {
                title: 'UVA - Inkoop',
                scope: 'Leveranciers',
                page: 'Detail',
                roles,
            },
        },
    ],
};
