import settings from '@/modules/settings/routes';
import maintenance from './maintenance';
import purchase from './purchase';
import rental from '@/modules/rental/routes';

import inventory from '@/modules/inventory/routes';
import client from '@/modules/client/routes';
import reports from '@/modules/reports/routes';
import planning from '@/modules/planning/routes';
import sales from '@/modules/sales/routes';
import service from '@/modules/service/routes';
import logistic from '@/modules/logistic/routes';

import { UserRole } from '@/models/UserRole';

export default {
    path: '/',
    component: () => import(/*webpackChunkName: "home" */ '@/modules/base/AdminBase.vue'),
    meta: {
        title: 'UVA - Home ',
        page: 'Home',
        roles: [UserRole.RentalUser, UserRole.SalesUser, UserRole.SiteAdmin, UserRole.Stock, UserRole.Logistics, UserRole.ClientManagement],
    },
    children: [
        {
            path: '',
            name: 'home',
            component: () => import(/*webpackChunkName: "home" */ '@/views/Home.vue'),
        },
        ...planning,
        ...rental,
        sales,
        client,
        inventory,
        logistic,
        settings,
        reports,
        maintenance,
        purchase,
        service,
    ],
};
