import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class YearMontSelector extends Vue {
    @Prop({ default: true, type: Boolean }) public showMonth: boolean;
    @Prop({ default: true, type: Boolean }) public showYear: boolean;
    @Prop({ default: false, type: Boolean }) public horizontal: boolean;
    @Prop({ default: true, type: Boolean }) public showLabels: boolean;
    @Prop({ default: false, type: Boolean }) public shortDate: boolean;
    @Prop({ default: 2019 }) public startYear: number;
    @Prop({ type: Number, default: null }) public year: number;
    @Prop({ type: Number, default: null }) public month: number;

    public get years() {
        const end = new Date().getFullYear();
        const years = [];

        for (let i = this.startYear; i <= end; i++) {
            years.push(i);
        }

        return years;
    }

    public onMonthInput(event: Event) {
        const select = event.target as HTMLInputElement;
        this.$emit('update:month', +select.value);
        this.$emit('input', {
            year: this.year,
            month: +select.value
        });
    }

    public onYearInput(event: Event) {
        const select = event.target as HTMLInputElement;
        this.$emit('update:year', +select.value);
        this.$emit('input', {
            year: +select.value,
            month: this.month
        });
    }
}
